import {batch} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'
import lowerCase from 'lodash/lowerCase'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import merge from 'lodash/merge'
import {getDateRange} from '../utils'
import {
  withBailOnLoading,
  setQuery,
  withFacilityType,
  isSupplyType,
} from './utils'
import {
  TOGGLE_CHARTS_GROUP_BY_SUPPLY_CATEGORY,
  TOGGLE_CHARTS_GROUP_BY_MANUFACTURER,
  TOGGLE_CHARTS_SHOW_MORE,
  TOGGLE_CHARTS_SHOW_NUMBERS,
  TOGGLE_CHARTS_SHOW_PHYSICIAN_NAMES,
  TOGGLE_CHARTS_SHOW_COSTS,
  SET_CHARTS_DATE_SAVED,
  SET_CHARTS_RISK_ADJUSTED,
  SET_CHARTS_QUALITY_METRIC,
  SET_CHARTS_FILTERS,
  SET_CHARTS_ALL_INPATIENT,
  SET_CHARTS_ALL_OUTPATIENT,
  SET_CHARTS_CONFIG,
  SET_CHARTS_CPTS,
  SET_CHARTS_CATEGORIES,
  SET_CHARTS_DATA,
  SET_CHARTS_DRGS,
  SET_CHARTS_ERROR,
  SET_CHARTS_EXCLUDE_IP,
  SET_CHARTS_EXCLUDE_OP,
  SET_CHARTS_HOSPITAL_CASE_NUMBERS,
  SET_CHARTS_ICDS,
  SET_CHARTS_INSTRUMENT_TYPES,
  SET_CHARTS_INSURANCE_TYPES,
  SET_CHARTS_LOADING,
  SET_CHARTS_NOT_LOADING,
  SET_CHARTS_LOADING_DATA,
  SET_CHARTS_NOT_LOADING_DATA,
  SET_CHARTS_PERCENTILES,
  SET_CHARTS_PROCEDURES,
  SET_CHARTS_TIME_PERIODS,
  SET_CHARTS_CANCER_SITE,
  SET_CHARTS_COMORBIDITIES,
  SET_CHARTS_HISTOLOGIES,
  SET_CHARTS_PROCEDURE_DETAILS,
  SET_CHARTS_STAGES,
  SET_CHARTS_DIAGNOSIS_CODES,
  SET_CHARTS_DROPPED_FILTERS,
  SET_CHARTS_DELETED,
  CLEAR_CHARTS_DELETED,
  TOGGLE_CHARTS_SHOW_PERCENTILES,
  TOGGLE_CHARTS_SHOW_ALL_CASE_SUPPLY_COSTS,
  TOGGLE_CHARTS_HIDE_CASE_REMOVAL_BUTTONS,
  SET_CHARTS_GROUP_BY_SURGEON,
  SET_CHARTS_GROUP_BY_ORGANIZATION,
  SET_CHARTS_EXCLUDE_DIAGNOSIS_CODES,
  SET_CHARTS_IS_ONCOLOGY,
  SET_CHARTS_EXCLUDE_ONCOLOGY,
  SET_CHARTS_IS_ROBOTICS,
  SET_CHARTS_EXCLUDE_ROBOTICS,
  SET_CHARTS_SUPPLY_PRODUCTS,
  SET_CHARTS_SUPPLY_MANUFACTURER_NUMBERS,
  SET_CHARTS_COST_BY_CASE_SEARCH,
  SET_CHARTS_COST_BY_SUPPLY_SEARCH,
  SET_CHARTS_DEPARTMENTS,
  UNSELECT_DEPARTMENT,
  UNSELECT_SUB_DEPARTMENT,
  SET_HIGH_VOLUME_PROCEDURE,
  SET_HIGH_VOLUME_PROCEDURE_SELECTION,
} from '../types'
import {fetchCharts} from '../../api'
import {
  fromQueryString,
  selectAll,
  selectOneById,
  selectOnlyOneById,
  getSelectedValues,
  unselectAll,
  unselectOneById,
  everySelected,
  everyUnselected,
  selectIsInFacilityType,
  updateObject,
  clone,
  selectDefaultDepartment,
} from '../../utils'
import {
  createConfig,
  createManufacturerNameOptions,
} from '../../pages/explore-data/utils'
import {fetchHighVolumeProcedures} from '../../api/charts'

const withDrgNames = filters => {
  const drgs = filters?.drgs.map(d => ({...d, name: `${d.name} (DRG ${d.id})`}))
  return {...filters, drgs}
}

export const createFilters = (filters, {isInFacilityType}) => {
  if (isInFacilityType)
    return withDrgNames(withFacilityType(filters, 'categories'))
  return withDrgNames(filters)
}

const getConfigFromParams = (config = {}, params) => {
  let options = config?.options
  let physicians = config?.physicians
  if (isSupplyType(config)) {
    const supply_category_names = params?.supply_category_names
    if (supply_category_names?.length > 0) {
      const formatted = supply_category_names.map(lowerCase)
      const values = config?.options?.values.map(v => {
        if (formatted?.includes(lowerCase(v.title))) {
          return {...v, selected: true}
        }
        return {...v, selected: false}
      })
      options = {title: config?.options?.title, values}
    }
  }
  const surgeon_ids = params?.surgeon_ids
  if (surgeon_ids?.length > 0) {
    physicians = config?.physicians?.map(p => {
      if (surgeon_ids?.includes(p.id)) {
        return {...p, selected: true}
      }
      return {...p, selected: false}
    })
  }
  return {...config, options, physicians}
}

const setFetchCharts =
  ({
    signal,
    search,
    initial,
    all_inpatient,
    all_outpatient,
    group_by_surgeon,
    group_by_organization,
    date_saved,
    bail,
    is_system,
    supply_category_names,
    surgeon_ids,
  }) =>
  (dispatch, getState) => {
    const {
      date_saved: current_date_saved,
      group_by_surgeon: current_group_by_surgeon,
      group_by_organization: current_group_by_organization,
      config: current_config,
    } = getState().charts
    const isInFacilityType = selectIsInFacilityType(getState())
    batch(() => {
      dispatch(setError(null))
      if (date_saved !== current_date_saved) {
        dispatch(setDateSaved(date_saved))
      }
      if (is_system && group_by_surgeon !== current_group_by_surgeon) {
        dispatch(setGroupBySurgeon(group_by_surgeon))
      }
      if (
        is_system &&
        group_by_organization !== current_group_by_organization
      ) {
        dispatch(setGroupByOrganization(group_by_organization))
      }
      if (bail) {
        dispatch(
          setConfig(
            getConfigFromParams(current_config, {
              supply_category_names,
              surgeon_ids,
            }),
          ),
        )
      }
    })
    if (bail) return undefined
    if (initial) {
      dispatch(setLoading())
    } else {
      dispatch(setDataLoading())
    }
    return fetchCharts({signal, search})
      .then(({data, filters, config, percentiles, dropped_filters}) => {
        const _config = createConfig({
          ...config,
          groupBySurgeon: is_system && group_by_surgeon,
          groupByOrganization: is_system && group_by_organization,
          data,
          isInFacilityType,
          isSystemView: is_system,
        })
        const _filters = createFilters(filters, {
          isInFacilityType,
        })
        batch(() => {
          dispatch(setData(_config.data))
          dispatch(setFilters(_filters))
          dispatch(
            setConfig(
              getConfigFromParams(_config, {
                supply_category_names,
                surgeon_ids,
              }),
            ),
          )
          dispatch(setPercentiles(percentiles))
          dispatch(setDroppedFilters(dropped_filters))
          dispatch(setAllInpatient(all_inpatient))
          dispatch(setAllOutpatient(all_outpatient))
          if (initial) {
            dispatch(setNotLoading())
            dispatch(setDataNotLoading())
          } else {
            dispatch(setDataNotLoading())
          }
        })
      })
      .catch(error => {
        batch(() => {
          dispatch(setError(error))
          if (initial) {
            dispatch(setNotLoading())
          } else {
            dispatch(setDataNotLoading())
          }
        })
      })
  }

const toggleGroupBySupplyCategory = () => ({
  type: TOGGLE_CHARTS_GROUP_BY_SUPPLY_CATEGORY,
})

const toggleGroupByManufacturer = () => ({
  type: TOGGLE_CHARTS_GROUP_BY_MANUFACTURER,
})

const toggleShowNumbers = () => ({type: TOGGLE_CHARTS_SHOW_NUMBERS})

const toggleShowCosts = () => ({type: TOGGLE_CHARTS_SHOW_COSTS})

const toggleShowPhysicianNames = () => ({
  type: TOGGLE_CHARTS_SHOW_PHYSICIAN_NAMES,
})

const setFilters = (payload, meta) => ({
  type: SET_CHARTS_FILTERS,
  payload,
  meta,
})

const setDroppedFilters = payload => ({
  type: SET_CHARTS_DROPPED_FILTERS,
  payload,
})

const setData = payload => ({type: SET_CHARTS_DATA, payload})

const setError = payload => ({type: SET_CHARTS_ERROR, payload})

const setLoading = () => ({type: SET_CHARTS_LOADING})

const setNotLoading = () => ({type: SET_CHARTS_NOT_LOADING})

const setDataLoading = () => ({type: SET_CHARTS_LOADING_DATA})

const setDataNotLoading = () => ({type: SET_CHARTS_NOT_LOADING_DATA})

const setConfig = payload => ({type: SET_CHARTS_CONFIG, payload})

const setPercentiles = payload => ({type: SET_CHARTS_PERCENTILES, payload})

const setExcludeIp = payload => ({type: SET_CHARTS_EXCLUDE_IP, payload})

const setExcludeOp = payload => ({type: SET_CHARTS_EXCLUDE_OP, payload})

const setAllInpatient = payload => ({type: SET_CHARTS_ALL_INPATIENT, payload})

const setAllOutpatient = payload => ({type: SET_CHARTS_ALL_OUTPATIENT, payload})

const setCategories = payload => ({type: SET_CHARTS_CATEGORIES, payload})

const setIcds = payload => ({type: SET_CHARTS_ICDS, payload})

const setDrgs = payload => ({type: SET_CHARTS_DRGS, payload})

const setCpts = payload => ({type: SET_CHARTS_CPTS, payload})

const setProcedures = payload => ({type: SET_CHARTS_PROCEDURES, payload})

const setCostByCaseSearch = payload => ({
  type: SET_CHARTS_COST_BY_CASE_SEARCH,
  payload,
})

const setCostBySupplySearch = payload => ({
  type: SET_CHARTS_COST_BY_SUPPLY_SEARCH,
  payload,
})

const setInsuranceTypes = payload => ({
  type: SET_CHARTS_INSURANCE_TYPES,
  payload,
})

const setInstrumentTypes = payload => ({
  type: SET_CHARTS_INSTRUMENT_TYPES,
  payload,
})

const setTimePeriods = payload => ({type: SET_CHARTS_TIME_PERIODS, payload})

const setHospitalCaseNumbers = payload => ({
  type: SET_CHARTS_HOSPITAL_CASE_NUMBERS,
  payload,
})

const setDateSaved = payload => ({type: SET_CHARTS_DATE_SAVED, payload})

const setCancerSite = payload => ({type: SET_CHARTS_CANCER_SITE, payload})

const setComorbidities = payload => ({type: SET_CHARTS_COMORBIDITIES, payload})

const setHistologies = payload => ({type: SET_CHARTS_HISTOLOGIES, payload})

const setProcedureDetails = payload => ({
  type: SET_CHARTS_PROCEDURE_DETAILS,
  payload,
})

const setSupplyProducts = payload => ({
  type: SET_CHARTS_SUPPLY_PRODUCTS,
  payload,
})

const setSupplyManufacturerNumbers = payload => ({
  type: SET_CHARTS_SUPPLY_MANUFACTURER_NUMBERS,
  payload,
})

const setStages = payload => ({type: SET_CHARTS_STAGES, payload})

const setDiagnosisCodes = payload => ({
  type: SET_CHARTS_DIAGNOSIS_CODES,
  payload,
})

const toggleShowPercentiles = () => ({type: TOGGLE_CHARTS_SHOW_PERCENTILES})

const toggleShowAllCaseSupplyCosts = () => ({
  type: TOGGLE_CHARTS_SHOW_ALL_CASE_SUPPLY_COSTS,
})

const toggleHideCaseRemovalButtons = () => ({
  type: TOGGLE_CHARTS_HIDE_CASE_REMOVAL_BUTTONS,
})

const setRiskAdjusted = payload => ({type: SET_CHARTS_RISK_ADJUSTED, payload})
const setQualityMetric = payload => ({type: SET_CHARTS_QUALITY_METRIC, payload})

const toggleShowMore = () => ({type: TOGGLE_CHARTS_SHOW_MORE})

const setDeleted = payload => ({type: SET_CHARTS_DELETED, payload})

const clearDeleted = () => ({type: CLEAR_CHARTS_DELETED})

const clearError = () => ({type: SET_CHARTS_ERROR, payload: null})

const setGroupBySurgeon = payload => ({
  type: SET_CHARTS_GROUP_BY_SURGEON,
  payload,
})

const setGroupByOrganization = payload => ({
  type: SET_CHARTS_GROUP_BY_ORGANIZATION,
  payload,
})

const setExcludeDiagnosisCodes = payload => ({
  type: SET_CHARTS_EXCLUDE_DIAGNOSIS_CODES,
  payload,
})

const setIsRobotics = payload => ({
  type: SET_CHARTS_IS_ROBOTICS,
  payload,
})

const setExcludeRobotics = payload => ({
  type: SET_CHARTS_EXCLUDE_ROBOTICS,
  payload,
})

const setIsOncology = payload => ({
  type: SET_CHARTS_IS_ONCOLOGY,
  payload,
})

const setExcludeOncology = payload => ({
  type: SET_CHARTS_EXCLUDE_ONCOLOGY,
  payload,
})

const toggleIcd = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_outpatient: current_all_outpatient} =
    getState().charts
  const {icds: current_icds, drgs, cpts, procedures} = current_filters
  const icds = current_icds.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  const everyIpUnselected = everyUnselected(icds, drgs)
  const everyOpUnselected =
    everyUnselected(cpts, procedures) && !current_all_outpatient
  batch(() => {
    dispatch(setIcds(icds))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(
      setQuery({
        history,
        onLoading: setDataLoading,
      }),
    )
  })
}

const toggleDrg = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_outpatient: current_all_outpatient} =
    getState().charts
  const {drgs: current_drgs, icds, cpts, procedures} = current_filters
  const drgs = current_drgs.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  const everyOpUnselected =
    everyUnselected(cpts, procedures) && !current_all_outpatient
  const everyIpUnselected = everyUnselected(icds, drgs)
  batch(() => {
    dispatch(setDrgs(drgs))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(
      setQuery({
        history,
        onLoading: setDataLoading,
      }),
    )
  })
}

const toggleCpt = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_inpatient: current_all_inpatient} =
    getState().charts
  const {cpts: current_cpts, procedures, icds, drgs} = current_filters
  const cpts = current_cpts.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  const everyIpUnselected =
    everyUnselected(drgs, icds) && !current_all_inpatient
  const everyOpUnselected = everyUnselected(cpts, procedures)
  batch(() => {
    dispatch(setCpts(cpts))
    if (everyOpUnselected && everyIpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else {
      dispatch(setExcludeOp(false))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(
      setQuery({
        history,
        onLoading: setDataLoading,
      }),
    )
  })
}

const toggleProcedure = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters, all_inpatient: current_all_inpatient} =
    getState().charts
  const {procedures: current_procedures, cpts, icds, drgs} = current_filters
  const procedures = current_procedures.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  const everyIpUnselected =
    everyUnselected(drgs, icds) && !current_all_inpatient
  const everyOpUnselected = everyUnselected(cpts, procedures)
  batch(() => {
    dispatch(setProcedures(procedures))
    if (everyOpUnselected && everyIpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else {
      dispatch(setExcludeOp(false))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(
      setQuery({
        history,
        onLoading: setDataLoading,
      }),
    )
  })
}

const toggleAllIpProcedures = history => (dispatch, getState) => {
  const {
    all_inpatient: current_all_inpatient,
    all_outpatient: current_all_outpatient,
    filters: current_filters,
  } = getState().charts
  const {cpts, procedures} = current_filters
  const all_inpatient = !current_all_inpatient
  const everyIpUnselected = !all_inpatient
  const everyOpUnselected =
    everyUnselected(cpts, procedures) && !current_all_outpatient
  batch(() => {
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(everyOpUnselected))
    }
    dispatch(setAllInpatient(all_inpatient))
    dispatch(
      setQuery({
        history,
        omit: ['icds', 'drgs'],
        onLoading: setDataLoading,
      }),
    )
  })
}

const toggleAllOpProcedures = history => (dispatch, getState) => {
  const {
    all_outpatient: current_all_outpatient,
    all_inpatient: current_all_inpatient,
    filters: current_filters,
  } = getState().charts
  const {icds, drgs} = current_filters
  const all_outpatient = !current_all_outpatient
  const everyOpUnselected = !all_outpatient
  const everyIpUnselected =
    everyUnselected(icds, drgs) && !current_all_inpatient
  batch(() => {
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else {
      dispatch(setExcludeOp(false))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(setAllOutpatient(all_outpatient))
    dispatch(
      setQuery({
        history,
        omit: ['cpts', 'procedures'],
        onLoading: setDataLoading,
      }),
    )
  })
}

const applyProcedures = (payload, history) => dispatch => {
  const everyIpUnselected =
    !payload.all_inpatient && everyUnselected(payload.icds, payload.drgs)
  const everyOpUnselected =
    !payload.all_outpatient && everyUnselected(payload.cpts, payload.procedures)
  batch(() => {
    dispatch(setDrgs(payload.drgs))
    dispatch(setCpts(payload.cpts))
    dispatch(setProcedures(payload.procedures))
    dispatch(setIcds(payload.icds))
    if (everyIpUnselected && everyOpUnselected) {
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
    } else if (everyOpUnselected && !everyIpUnselected) {
      dispatch(setExcludeOp(true))
      dispatch(setExcludeIp(false))
    } else if (everyIpUnselected && !everyOpUnselected) {
      dispatch(setExcludeIp(true))
      dispatch(setExcludeOp(false))
    } else {
      dispatch(setExcludeOp(everyOpUnselected))
      dispatch(setExcludeIp(everyIpUnselected))
    }
    dispatch(setAllOutpatient(payload.all_outpatient))
    dispatch(setAllInpatient(payload.all_inpatient))
    dispatch(
      setQuery({
        history,
        onLoading: setDataLoading,
      }),
    )
  })
}

const siblings = {
  48: [48, 49],
  49: [48, 49],
  96: [96, 97, 98],
  97: [96, 97, 98],
  98: [96, 97, 98],
}

const selectCategory = (payload, history) => (dispatch, getState) => {
  const {
    filters: current_filters,
    date_saved: current_date_saved,
    show_costs,
  } = getState().charts
  const {categories: current_categories, time_periods: current_time_periods} =
    current_filters
  const categories = current_categories.map(selectOnlyOneById(payload))
  const time_periods = current_date_saved
    ? current_time_periods
    : {
        selected_start: '',
        selected_end: '',
        values: current_time_periods.values.map(unselectAll),
      }
  const current_id = Number(
    getSelectedValues(current_categories)
      ?.filter(c => c.selected)
      ?.map(c => c.id)
      ?.join(),
  )
  const next_id = Number(
    getSelectedValues(categories)
      ?.filter(c => c.selected)
      ?.map(c => c.id)
      ?.join(),
  )
  const next_siblings = siblings[current_id]

  batch(() => {
    if (show_costs) {
      dispatch(toggleShowCosts())
    }
    if (next_siblings && next_siblings.includes(next_id)) {
      dispatch(setHospitalCaseNumbers([]))
    } else {
      dispatch(setSupplyManufacturerNumbers([]))
      dispatch(setSupplyProducts([]))
      dispatch(setHospitalCaseNumbers([]))
    }
    dispatch(setCategories(categories))

    dispatch(
      setQuery({
        history,
        overrides: {
          time_periods,
          supply_categories: [],
          surgeons: [],
        },
        onLoading: setDataLoading,
      }),
    )
  })
}

const selectTimePeriod = (payload, history) => (dispatch, getState) => {
  const {time_periods: current_time_periods} = getState().charts.filters
  const time_periods = {
    ...current_time_periods,
    selected_start: payload.selected_start,
    selected_end: payload.selected_end,
    values: current_time_periods.values.map(selectOnlyOneById(payload.value)),
  }
  if (payload.value.id === 5 && payload.selected_start === '') {
    const {id} = current_time_periods.values.find(d => d.selected)
    const range = getDateRange(id, time_periods.max)
    dispatch(setTimePeriods({...time_periods, ...range}))
  } else {
    batch(() => {
      dispatch(setDateSaved(true))
      dispatch(setTimePeriods(time_periods))
      dispatch(
        setQuery({
          history,
          onLoading: setDataLoading,
        }),
      )
    })
  }
}

const unselectAllTimePeriods = history => (dispatch, getState) => {
  const {time_periods: current_time_periods} = getState().charts.filters
  const time_periods = {
    ...current_time_periods,
    selected_start: '',
    selected_end: '',
    values: current_time_periods.values.map(unselectAll),
  }
  dispatch(
    setQuery({
      history,
      overrides: {
        time_periods,
      },
      onLoading: setDataLoading,
    }),
  )
}

const selectInstrumentType = (payload, history) => (dispatch, getState) => {
  const {instrument_types: current_instrument_types} = getState().charts.filters
  const instrument_types = current_instrument_types.map(
    selectOnlyOneById(payload),
  )
  batch(() => {
    dispatch(setInstrumentTypes(instrument_types))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const selectCostByCaseSearch = payload => (dispatch, getState) => {
  const {cost_by_case_search: current_cost_by_case_search} = getState().charts
  const cost_by_case_search = current_cost_by_case_search.map(
    selectOnlyOneById(payload),
  )
  dispatch(setCostByCaseSearch(cost_by_case_search))
}

const selectCostBySupplySearch = payload => (dispatch, getState) => {
  const {cost_by_supply_search: current_cost_by_supply_search} =
    getState().charts
  const cost_by_supply_search = current_cost_by_supply_search.map(
    selectOnlyOneById(payload),
  )
  dispatch(setCostBySupplySearch(cost_by_supply_search))
}

const unselectAllInstrumentTypes = history => (dispatch, getState) => {
  const {instrument_types: current_instrument_types} = getState().charts.filters
  const instrument_types = current_instrument_types.map(unselectAll)
  batch(() => {
    dispatch(setInstrumentTypes(instrument_types))
    dispatch(
      setQuery({
        history,
        onLoading: setDataLoading,
      }),
    )
  })
}

const toggleInsuranceType = (payload, history) => (dispatch, getState) => {
  const {insurance_types: current_insurance_types} = getState().charts.filters
  const insurance_types = current_insurance_types.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setInsuranceTypes(insurance_types))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const toggleInsuranceTypeList = (payload, history) => dispatch => {
  batch(() => {
    dispatch(setInsuranceTypes(payload))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const applyHospitalCaseNumberSelections = (payload, history) => dispatch => {
  batch(() => {
    dispatch(setSupplyManufacturerNumbers([]))
    dispatch(setSupplyProducts([]))
    dispatch(setHospitalCaseNumbers(payload))
    dispatch(
      setQuery({
        history,
        overrides: {
          hospital_case_numbers: payload,
          supply_products: [],
          supply_manufacturer_numbers: [],
        },
        onLoading: setDataLoading,
      }),
    )
  })
}

const unselectHospitalCaseNumber =
  (payload, history) => (dispatch, getState) => {
    const {hospital_case_numbers: current_hospital_case_numbers} =
      getState().charts.filters
    const hospital_case_numbers = current_hospital_case_numbers.filter(
      h => h !== payload.id,
    )
    batch(() => {
      dispatch(setHospitalCaseNumbers(hospital_case_numbers))
      dispatch(
        setQuery({
          history,
          overrides: {hospital_case_numbers},
          onLoading: setDataLoading,
        }),
      )
    })
  }

const applySupplyManufacturerNumberSelections =
  (payload, history) => dispatch => {
    batch(() => {
      dispatch(setHospitalCaseNumbers([]))
      dispatch(setSupplyProducts([]))
      dispatch(setSupplyManufacturerNumbers(payload))
      dispatch(
        setQuery({
          history,
          overrides: {
            supply_manufacturer_numbers: payload,
            supply_products: [],
            hospital_case_numbers: [],
          },
          onLoading: setDataLoading,
        }),
      )
    })
  }

const unselectSupplyManufacturerNumber =
  (payload, history) => (dispatch, getState) => {
    const {supply_manufacturer_numbers: current_supply_manufacturer_numbers} =
      getState().charts.filters
    const supply_manufacturer_numbers =
      current_supply_manufacturer_numbers.filter(h => h !== payload.id)
    batch(() => {
      dispatch(setSupplyManufacturerNumbers(supply_manufacturer_numbers))
      dispatch(
        setQuery({
          history,
          overrides: {supply_manufacturer_numbers},
          onLoading: setDataLoading,
        }),
      )
    })
  }

const applySupplyProductSelections = (payload, history) => dispatch => {
  batch(() => {
    dispatch(setHospitalCaseNumbers([]))
    dispatch(setSupplyManufacturerNumbers([]))
    dispatch(setSupplyProducts(payload))
    dispatch(
      setQuery({
        history,
        overrides: {
          supply_products: payload,
          supply_manufacturer_numbers: [],
          hospital_case_numbers: [],
        },
        onLoading: setDataLoading,
      }),
    )
  })
}

const unselectSupplyProduct = (payload, history) => (dispatch, getState) => {
  const {supply_products: current_supply_products} = getState().charts.filters
  const supply_products = current_supply_products.filter(
    sp => sp.id !== payload.id,
  )
  batch(() => {
    dispatch(setSupplyProducts(supply_products))
    dispatch(
      setQuery({
        history,
        overrides: {supply_products},
        onLoading: setDataLoading,
      }),
    )
  })
}

const togglePhysician = (payload, history) => (dispatch, getState) => {
  const {config: current_config} = getState().charts
  const config = {
    ...current_config,
    physicians: current_config.physicians.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    ),
  }

  batch(() => {
    dispatch(setConfig(config))
    dispatch(
      setQuery({
        history,
        fetch: false,
      }),
    )
  })
}

const toggleAllPhysicians = history => (dispatch, getState) => {
  const {config: current_config} = getState().charts
  const everyPhysicianSelected = everySelected(current_config.physicians)
  const config = {
    ...current_config,
    physicians: current_config.physicians.map(
      everyPhysicianSelected ? unselectAll : selectAll,
    ),
  }
  batch(() => {
    dispatch(setConfig(config))
    dispatch(
      setQuery({
        history,
        fetch: false,
      }),
    )
  })
}

const toggleOrganization = payload => (dispatch, getState) => {
  const {config: current_config} = getState().charts
  const config = {
    ...current_config,
    organizations: current_config.organizations.map(
      payload.selected ? unselectOneById(payload) : selectOneById(payload),
    ),
  }

  dispatch(setConfig(config))
}

const toggleAllOrganizations = () => (dispatch, getState) => {
  const {config: current_config} = getState().charts
  const everyOrganizationSelected = everySelected(current_config.organizations)
  const config = {
    ...current_config,
    organizations: current_config.organizations.map(
      everyOrganizationSelected ? unselectAll : selectAll,
    ),
  }
  dispatch(setConfig(config))
}

const toggleOption = (payload, history) => (dispatch, getState) => {
  const {config: current_config} = getState().charts
  const config = {
    ...current_config,
    options: {
      ...current_config.options,
      values: current_config.options.values.map(
        payload.selected ? unselectOneById(payload) : selectOneById(payload),
      ),
    },
  }
  if (isSupplyType(current_config)) {
    batch(() => {
      dispatch(setConfig(config))
      dispatch(
        setQuery({
          history,
          fetch: false,
        }),
      )
    })
  } else {
    dispatch(setConfig(config))
  }
}

const toggleAllOptions = history => (dispatch, getState) => {
  const {config: current_config} = getState().charts
  const everyOptionSelected = everySelected(current_config.options.values)
  const config = {
    ...current_config,
    options: {
      ...current_config.options,
      values: current_config.options.values.map(
        everyOptionSelected ? unselectAll : selectAll,
      ),
    },
  }
  if (isSupplyType(current_config)) {
    batch(() => {
      dispatch(setConfig(config))
      dispatch(
        setQuery({
          history,
          fetch: false,
        }),
      )
    })
  } else {
    dispatch(setConfig(config))
  }
}

const callFetchHVAPI =
  ({signal, search, callback}) =>
  dispatch => {
    return fetchHighVolumeProcedures({signal, search})
      .then(({data}) => {
        batch(() => {
          dispatch(setHighVolumeProcedure(data))
          callback()
        })
      })
      .catch(error => {
        dispatch(setError(error))
      })
  }

const fetchHighVolumeProcedure =
  ({signal, search, initial, callback}) =>
  (dispatch, getState) => {
    const {department_ids} = fromQueryString(search, ['department_ids'])

    const default_department = selectDefaultDepartment(getState())?.id
    const refresh_date = getState()
      .config.user.organizations.filter(o => o.selected)
      .map(o => o.refresh_date)
      .join()
    let query = isEmpty(search)
      ? `?refresh_date=${refresh_date}`
      : `?refresh_date=${refresh_date}&${search.slice(1)}`
    const isOncologyDepartment =
      department_ids === 4 ||
      (Array.isArray(department_ids) && department_ids.includes(4))

    if (isOncologyDepartment) {
      query = `${query}&is_oncology=true`
    }

    query = new URLSearchParams(query)

    if (!department_ids && default_department) {
      query.append('department_ids', default_department)
    }

    dispatch(
      callFetchHVAPI({
        signal,
        search: `?${query.toString()}`,
        initial,
        callback,
      }),
    )
  }
const fetch =
  ({signal, search, initial = false, bail}) =>
  (dispatch, getState) => {
    const {
      date_saved = false,
      all_inpatient,
      all_outpatient,
      group_by_surgeon,
      group_by_organization,
      supply_category_names,
      surgeon_ids,
      department_ids,
    } = fromQueryString(search, [
      'date_saved',
      'all_inpatient',
      'all_outpatient',
      'group_by_surgeon',
      'group_by_organization',
      'supply_category_names',
      'surgeon_ids',
      'department_ids',
    ])
    const is_system = getState().config.user.organizations.filter(
      o => o.selected && o.system,
    ).length
    const refresh_date = getState()
      .config.user.organizations.filter(o => o.selected)
      .map(o => o.refresh_date)
      .join()
    const default_department = selectDefaultDepartment(getState())?.id
    let query = isEmpty(search)
      ? `?refresh_date=${refresh_date}`
      : `?refresh_date=${refresh_date}&${search.slice(1)}`

    const isOncologyDepartment =
      department_ids === 4 ||
      (Array.isArray(department_ids) && department_ids.includes(4))

    if (isOncologyDepartment) {
      query = `${query}&is_oncology=true`
    }

    query = new URLSearchParams(query)

    if (!department_ids && default_department) {
      query.append('department_ids', default_department)
    }

    if (is_system && group_by_surgeon == null) {
      query.append('group_by_surgeon', 'true')
    }

    dispatch(
      setFetchCharts({
        signal,
        search: query.toString(),
        initial,
        all_inpatient,
        all_outpatient,
        group_by_surgeon: isBoolean(group_by_surgeon) ? group_by_surgeon : true,
        group_by_organization: !!group_by_organization,
        date_saved,
        bail,
        is_system,
        supply_category_names: isString(supply_category_names)
          ? [supply_category_names]
          : supply_category_names,
        surgeon_ids:
          isNumber(surgeon_ids) || isString(surgeon_ids)
            ? [surgeon_ids]
            : surgeon_ids,
      }),
    )
  }

const handleToggleRiskAdjusted = (payload, history) => (dispatch, getState) => {
  const {risk_adjusted: current_risk_adjusted} = getState().charts.filters
  if (current_risk_adjusted !== payload) {
    const risk_adjusted = payload
    batch(() => {
      dispatch(setRiskAdjusted(risk_adjusted))
      dispatch(setQuery({history, onLoading: setDataLoading}))
    })
  }
}

const handleToggleQualityMetric =
  (payload, history) => (dispatch, getState) => {
    const {has_quality_metric: current_quality_metric} =
      getState().charts.filters
    if (current_quality_metric !== payload) {
      const has_quality_metric = payload
      batch(() => {
        dispatch(setQualityMetric(has_quality_metric))
        dispatch(setQuery({history, onLoading: setDataLoading}))
      })
    }
  }

const handleSetIsOncology = (payload, history) => (dispatch, getState) => {
  const {is_oncology: current_is_oncology} = getState().charts.filters
  if (current_is_oncology !== payload) {
    batch(() => {
      dispatch(setIsOncology(payload))
      dispatch(setExcludeOncology(false))
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
      dispatch(
        setQuery({
          history,
          store: 'charts',
          onLoading: setDataLoading,
        }),
      )
    })
  }
}

const handleSetAllCases = history => dispatch => {
  batch(() => {
    dispatch(setIsOncology(false))
    dispatch(setExcludeOncology(false))
    dispatch(setExcludeIp(false))
    dispatch(setExcludeOp(false))
    dispatch(
      setQuery({
        history,
        store: 'charts',
        onLoading: setDataLoading,
      }),
    )
  })
}

const handleSetExcludeOncology = (payload, history) => (dispatch, getState) => {
  const {exclude_oncology: current_exclude_oncology} = getState().charts.filters
  if (current_exclude_oncology !== payload) {
    batch(() => {
      dispatch(setExcludeOncology(payload))
      dispatch(setIsOncology(false))
      dispatch(setExcludeIp(false))
      dispatch(setExcludeOp(false))
      dispatch(
        setQuery({
          history,
          store: 'charts',
          onLoading: setDataLoading,
        }),
      )
    })
  }
}

const handleSetIsRobotics = (payload, history) => (dispatch, getState) => {
  const {is_robotics: current_is_robotics} = getState().charts.filters
  if (current_is_robotics !== payload) {
    batch(() => {
      dispatch(setIsRobotics(payload))
      dispatch(setExcludeRobotics(false))
      dispatch(
        setQuery({
          history,
          store: 'charts',
          onLoading: setDataLoading,
        }),
      )
    })
  }
}

const handleSetAllRoboticsCases = history => dispatch => {
  batch(() => {
    dispatch(setIsRobotics(false))
    dispatch(setExcludeRobotics(false))
    dispatch(
      setQuery({
        history,
        store: 'charts',
        onLoading: setDataLoading,
      }),
    )
  })
}

const handleSetExcludeRobotics = (payload, history) => (dispatch, getState) => {
  const {exclude_robotics: current_exclude_robotics} = getState().charts.filters
  if (current_exclude_robotics !== payload) {
    batch(() => {
      dispatch(setExcludeRobotics(payload))
      dispatch(setIsRobotics(false))
      dispatch(
        setQuery({
          history,
          store: 'charts',
          onLoading: setDataLoading,
        }),
      )
    })
  }
}

const handleSetGroupBy = (payload, history) => dispatch => {
  if (payload.id === 3) {
    batch(() => {
      dispatch(setGroupBySurgeon(true))
      dispatch(setGroupByOrganization(true))
      dispatch(setQuery({history, onLoading: setDataLoading}))
    })
  } else if (payload.id === 2) {
    batch(() => {
      dispatch(setGroupBySurgeon(false))
      dispatch(setGroupByOrganization(false))
      dispatch(setQuery({history, onLoading: setDataLoading}))
    })
  } else {
    batch(() => {
      dispatch(setGroupBySurgeon(true))
      dispatch(setGroupByOrganization(false))
      dispatch(setQuery({history, onLoading: setDataLoading}))
    })
  }
}

const toggleCancerSites = (payload, history) => (dispatch, getState) => {
  const {cancer_sites: current_cancer_sites} = getState().charts.filters
  const cancer_sites = current_cancer_sites.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setCancerSite(cancer_sites))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const toggleComorbidities = (payload, history) => (dispatch, getState) => {
  const {comorbidities: current_comorbidities} = getState().charts.filters
  const comorbidities = current_comorbidities.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setComorbidities(comorbidities))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const toggleHistologies = (payload, history) => (dispatch, getState) => {
  const {histologies: current_histologies} = getState().charts.filters
  const histologies = current_histologies.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setHistologies(histologies))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}
const toggleProcedureDetails = (payload, history) => (dispatch, getState) => {
  const {procedure_details: current_procedure_details} =
    getState().charts.filters
  const procedure_details = current_procedure_details.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setProcedureDetails(procedure_details))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const toggleStages = (payload, history) => (dispatch, getState) => {
  const {stages: current_stages} = getState().charts.filters
  const stages = current_stages.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setStages(stages))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const toggleAllStages = (payload, history) => (dispatch, getState) => {
  const {stages: current_stages} = getState().charts.filters
  const stages = current_stages.map(s => ({...s, selected: !payload}))
  batch(() => {
    dispatch(setStages(stages))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const toggleAllHistologies = (payload, history) => (dispatch, getState) => {
  const {histologies: current_histologies} = getState().charts.filters
  const histologies = current_histologies.map(h => ({...h, selected: !payload}))
  batch(() => {
    dispatch(setHistologies(histologies))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const toggleAllCancerSites = (payload, history) => (dispatch, getState) => {
  const {cancer_sites: current_cancer_sites} = getState().charts.filters
  const cancer_sites = current_cancer_sites.map(c => ({
    ...c,
    selected: !payload,
  }))
  batch(() => {
    dispatch(setCancerSite(cancer_sites))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const handleToggleDiagnosisCodes = (dcs, payload) =>
  dcs.map(dc => {
    const selected = isBoolean(payload)
      ? payload
      : payload.id === dc.code
      ? !dc.selected
      : dc.selected
    const diagnosis_codes = dc.diagnosis_codes
      ? handleToggleDiagnosisCodes(
          dc.diagnosis_codes,
          payload.id === dc.code ? selected : payload,
        )
      : undefined
    return {
      ...dc,
      selected: diagnosis_codes ? everySelected(diagnosis_codes) : selected,
      diagnosis_codes,
    }
  })
const toggleDiagnosisCodes = (payload, history) => (dispatch, getState) => {
  const {diagnosis_codes: current_diagnosis_codes} = getState().charts.filters
  const diagnosis_codes = handleToggleDiagnosisCodes(
    current_diagnosis_codes,
    payload,
  )
  batch(() => {
    dispatch(setDiagnosisCodes(diagnosis_codes))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

function syncCheckboxDiagnosisStateToReduxDiagnosis(checkboxState, reduxState) {
  const map = new Map()

  const populateMap = arr => {
    arr.forEach(item => {
      map.set(item.id, item)
      if (item.items || item.diagnosis_codes) {
        populateMap(item.items || item.diagnosis_codes)
      }
    })
  }

  populateMap(checkboxState)

  const createUpdatedArray = arr => {
    return arr.map(item => {
      const correspondingItem = map.get(item.code)
      const newItem = {
        ...item,
        selected: correspondingItem
          ? correspondingItem.selected
          : item.selected,
      }
      if (item.diagnosis_codes) {
        newItem.diagnosis_codes = createUpdatedArray(item.diagnosis_codes)
      }
      return newItem
    })
  }

  return createUpdatedArray(reduxState)
}

const toggleDiagnosisCodesList = (payload, history) => (dispatch, getState) => {
  let {diagnosis_codes: current} = getState().charts.filters

  const updatedReduxData = syncCheckboxDiagnosisStateToReduxDiagnosis(
    payload,
    current,
  )

  batch(() => {
    dispatch(setDiagnosisCodes(updatedReduxData))
    dispatch(setQuery({history, onLoading: setDataLoading}))
  })
}

const resetFilters = history => (dispatch, getState) => {
  const {
    icds: current_icds,
    drgs: current_drgs,
    cpts: current_cpts,
    insurance_types: current_insurance_types,
    procedures: current_procedures,
    instrument_types: current_instrument_types,
    cancer_sites: current_cancer_sites,
    comorbidities: current_comorbidities,
    histologies: current_histologies,
    procedure_details: current_procedure_details,
    stages: current_stages,
  } = getState().charts.filters
  const icds = current_icds.map(unselectAll)
  const drgs = current_drgs.map(unselectAll)
  const cpts = current_cpts.map(unselectAll)
  const insurance_types = current_insurance_types.map(unselectAll)
  const procedures = current_procedures.map(unselectAll)
  const instrument_types = current_instrument_types.map(unselectAll)
  const cancer_sites = current_cancer_sites.map(unselectAll)
  const comorbidities = current_comorbidities.map(unselectAll)
  const histologies = current_histologies.map(unselectAll)
  const procedure_details = current_procedure_details.map(unselectAll)
  const stages = current_stages.map(unselectAll)
  dispatch(
    setQuery({
      history,
      overrides: {
        icds,
        drgs,
        cpts,
        insurance_types,
        procedures,
        instrument_types,
        cancer_sites,
        comorbidities,
        histologies,
        procedure_details,
        stages,
        hospital_case_numbers: [],
        supply_manufacturer_numbers: [],
        supply_products: [],
        diagnosis_codes: [],
        risk_adjusted: null,
        has_quality_metric: null,
        date_saved: null,
        all_inpatient: null,
        all_outpatient: null,
        group_by_surgeon: true,
        group_by_organization: false,
        exclude_diagnosis_codes: null,
        is_oncology: null,
        exclude_oncology: null,
        exclude_ip: null,
        exclude_op: null,
        supply_categories: [],
        departments: [],
      },
      onLoading: setDataLoading,
    }),
  )
}

const toggleDateSaved = history => (dispatch, getState) => {
  const {date_saved: current_date_saved} = getState().charts
  const date_saved = !current_date_saved
  batch(() => {
    dispatch(setDateSaved(date_saved))
    dispatch(
      setQuery({
        history,
        fetch: false,
      }),
    )
  })
}

const _toggleGroupByManufacturer = () => dispatch => {
  dispatch(toggleGroupByManufacturer())
  dispatch(setDataLoading())
}
const _toggleGroupBySupplyCategory = () => dispatch => {
  dispatch(toggleGroupBySupplyCategory())
  dispatch(setDataLoading())
}

const _toggleShowPhysicianNames = () => (dispatch, getState) => {
  dispatch(toggleShowPhysicianNames())
  const {id} = getState().charts.config
  if (id === 49) {
    dispatch(setDataLoading())
  }
}

const toggleExcludeDiagnosisCodes = (payload, history) => dispatch => {
  batch(() => {
    dispatch(setExcludeDiagnosisCodes(payload))
    dispatch(
      setQuery({
        history,
        overrides: {
          diagnosis_codes: [],
        },
        onLoading: setDataLoading,
      }),
    )
  })
}

const handleToggleShowCosts = () => (dispatch, getState) => {
  const {show_costs: current_show_costs, config: current_config} =
    getState().charts
  dispatch(setDataLoading())
  const show_costs = !current_show_costs
  let chart = clone(current_config?.chart)
  if (show_costs) {
    if (current_config.id === 111) {
      updateObject(chart, 'left_axis', {tick_format: '$'})
    } else {
      updateObject(chart, 'tick_format', '$')
    }

    const config = merge({}, current_config, {
      options: createManufacturerNameOptions(current_config, 'cost'),
      chart,
    })

    batch(() => {
      setTimeout(() => {
        dispatch(setConfig(config))
        dispatch(toggleShowCosts())
        dispatch(setDataNotLoading())
      }, 1000)
    })
  } else {
    if (current_config.id === 111) {
      updateObject(chart, 'left_axis', {tick_format: '%'})
    } else {
      updateObject(chart, 'tick_format', '%')
    }

    const config = merge({}, current_config, {
      options: createManufacturerNameOptions(current_config, 'percent'),
      chart,
    })
    batch(() => {
      setTimeout(() => {
        dispatch(setConfig(config))
        dispatch(toggleShowCosts())
        dispatch(setDataNotLoading())
      }, 1000)
    })
  }
}

const setChartLoadingStates = () => dispatch => {
  dispatch(setLoading(true))
  dispatch(setDataLoading(true))
}

const setDepartments = payload => ({
  type: SET_CHARTS_DEPARTMENTS,
  payload,
})

const handleDepartmentsSelection =
  (history, payload) => (dispatch, getState) => {
    const {filters: current_filters} = getState().charts
    const {departments: current_departments} = current_filters
    // Track if department_id 4 was previously selected
    const wasOncologyPreviouslySelected = current_departments.some(
      department =>
        department.id === 4 &&
        (department.selected ||
          department.sub_departments.some(subDept => subDept.selected)),
    )
    // Check if any department with id 4 is still selected after the update
    const isOncologyCurrentlySelected = payload?.some(
      department =>
        department.id === 4 &&
        (department.selected ||
          department.sub_departments.some(subDept => subDept.selected)),
    )
    const isOncology =
      wasOncologyPreviouslySelected && !isOncologyCurrentlySelected
        ? false
        : current_filters.is_oncology
    batch(() => {
      dispatch(setIsOncology(isOncology))
      dispatch(setDepartments(payload))
      dispatch(setQuery({history, store: 'charts'}))
    })
  }

const unselectDepartment = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().charts
  const {departments: current_departments} = current_filters

  // Track if department_id 4 was previously selected
  const wasOncologyPreviouslySelected = current_departments.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  // Dispatch the unselect department action
  batch(() => {
    dispatch({type: UNSELECT_DEPARTMENT, payload})

    // Fetch the updated state after unselecting the department
    const {filters: updated_filters} = getState().charts
    const {departments: updated_departments} = updated_filters

    // Check if any department with id 4 is still selected after the update
    const isOncologyCurrentlySelected = updated_departments.some(
      department =>
        department.id === 4 &&
        (department.selected ||
          department.sub_departments.some(subDept => subDept.selected)),
    )

    // Set is_oncology to false only if it was previously selected and is now unselected
    const isOncology =
      wasOncologyPreviouslySelected && !isOncologyCurrentlySelected
        ? false
        : current_filters.is_oncology
    dispatch(setIsOncology(isOncology))
    dispatch(
      setQuery({
        history,
        store: 'charts',
      }),
    )
  })
}

const unselectSubDepartment = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().charts
  const {departments: current_departments} = current_filters

  // Track if department_id 4 was previously selected
  const wasOncologyPreviouslySelected = current_departments.some(
    department =>
      department.id === 4 &&
      (department.selected ||
        department.sub_departments.some(subDept => subDept.selected)),
  )

  // Dispatch the unselect sub-department action
  batch(() => {
    dispatch({type: UNSELECT_SUB_DEPARTMENT, payload})

    // Fetch the updated state after unselecting the sub-department
    const {filters: updated_filters} = getState().charts
    const {departments: updated_departments} = updated_filters

    // Check if any department with id 4 is still selected after the update
    const isOncologyCurrentlySelected = updated_departments.some(
      department =>
        department.id === 4 &&
        (department.selected ||
          department.sub_departments.some(subDept => subDept.selected)),
    )

    // Set is_oncology to false only if it was previously selected and is now unselected
    const isOncology =
      wasOncologyPreviouslySelected && !isOncologyCurrentlySelected
        ? false
        : current_filters.is_oncology
    dispatch(setIsOncology(isOncology))
    dispatch(
      setQuery({
        history,
        store: 'charts',
      }),
    )
  })
}

const setHighVolumeProcedure = payload => dispatch => {
  dispatch({type: SET_HIGH_VOLUME_PROCEDURE, payload})
}

const setHighVolumeProcedureSelection = payload => dispatch => {
  dispatch({type: SET_HIGH_VOLUME_PROCEDURE_SELECTION, payload})
}
const actions = {
  toggleGroupBySupplyCategory: withBailOnLoading(_toggleGroupBySupplyCategory),
  toggleGroupByManufacturer: withBailOnLoading(_toggleGroupByManufacturer),
  toggleShowMore,
  toggleShowNumbers,
  toggleShowCosts: handleToggleShowCosts,
  toggleShowPhysicianNames: withBailOnLoading(_toggleShowPhysicianNames),

  toggleIcd: withBailOnLoading(toggleIcd),
  toggleDrg: withBailOnLoading(toggleDrg),
  toggleCpt: withBailOnLoading(toggleCpt),
  toggleProcedure: withBailOnLoading(toggleProcedure),
  toggleAllIpProcedures: withBailOnLoading(toggleAllIpProcedures),
  toggleAllOpProcedures: withBailOnLoading(toggleAllOpProcedures),
  selectCategory: withBailOnLoading(selectCategory),
  selectTimePeriod: withBailOnLoading(selectTimePeriod),
  unselectAllTimePeriods: withBailOnLoading(unselectAllTimePeriods),
  selectInstrumentType: withBailOnLoading(selectInstrumentType),
  unselectAllInstrumentTypes: withBailOnLoading(unselectAllInstrumentTypes),
  toggleInsuranceType: withBailOnLoading(toggleInsuranceType),
  toggleInsuranceTypeList: withBailOnLoading(toggleInsuranceTypeList),
  applySupplyProductSelections: withBailOnLoading(applySupplyProductSelections),
  unselectSupplyProduct: withBailOnLoading(unselectSupplyProduct),
  applyHospitalCaseNumberSelections: withBailOnLoading(
    applyHospitalCaseNumberSelections,
  ),
  unselectHospitalCaseNumber: withBailOnLoading(unselectHospitalCaseNumber),
  applySupplyManufacturerNumberSelections: withBailOnLoading(
    applySupplyManufacturerNumberSelections,
  ),
  unselectSupplyManufacturerNumber: withBailOnLoading(
    unselectSupplyManufacturerNumber,
  ),
  togglePhysician,
  toggleAllPhysicians,
  toggleOption,
  toggleOrganization,
  toggleAllOrganizations,
  toggleAllOptions,
  toggleDateSaved: withBailOnLoading(toggleDateSaved),
  toggleShowPercentiles,
  toggleShowAllCaseSupplyCosts,
  toggleHideCaseRemovalButtons,
  toggleCancerSites: withBailOnLoading(toggleCancerSites),
  toggleComorbidities: withBailOnLoading(toggleComorbidities),
  toggleHistologies: withBailOnLoading(toggleHistologies),
  toggleProcedureDetails: withBailOnLoading(toggleProcedureDetails),
  toggleStages: withBailOnLoading(toggleStages),
  toggleAllStages: withBailOnLoading(toggleAllStages),
  toggleAllHistologies: withBailOnLoading(toggleAllHistologies),
  toggleAllCancerSites: withBailOnLoading(toggleAllCancerSites),
  toggleDiagnosisCodes: withBailOnLoading(toggleDiagnosisCodes),
  toggleDiagnosisCodesList: withBailOnLoading(toggleDiagnosisCodesList),
  toggleRiskAdjusted: withBailOnLoading(handleToggleRiskAdjusted),
  toggleQualityMetric: withBailOnLoading(handleToggleQualityMetric),
  resetFilters: withBailOnLoading(resetFilters),
  setDeleted,
  clearDeleted,
  fetch,
  clearError,
  setDataNotLoading,
  setGroupBy: withBailOnLoading(handleSetGroupBy),
  toggleExcludeDiagnosisCodes: withBailOnLoading(toggleExcludeDiagnosisCodes),
  setIsOncology: withBailOnLoading(handleSetIsOncology),
  setExcludeOncology: withBailOnLoading(handleSetExcludeOncology),
  setAllCases: withBailOnLoading(handleSetAllCases),
  setIsRobotics: withBailOnLoading(handleSetIsRobotics),
  setExcludeRobotics: withBailOnLoading(handleSetExcludeRobotics),
  setAllRoboticsCases: withBailOnLoading(handleSetAllRoboticsCases),
  selectCostByCaseSearch,
  selectCostBySupplySearch,
  applyProcedures: withBailOnLoading(applyProcedures),
  setChartLoadingStates,
  setDepartments: withBailOnLoading(handleDepartmentsSelection),
  unselectSubDepartment: withBailOnLoading(unselectSubDepartment),
  unselectDepartment: withBailOnLoading(unselectDepartment),
  fetchHighVolumeProcedure: withBailOnLoading(fetchHighVolumeProcedure),
  setHighVolumeProcedure,
  setHighVolumeProcedureSelection,
}

export default actions
