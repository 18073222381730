import {useMemo} from 'react'
import {Link} from 'react-router-dom'

import {AgRow, AgFixed, AgImage, AgBox} from '@aghealth/ag-components'

import {
  ProfileMenu,
  SupportMenu,
  ScorecardIcon,
  AlertIcon,
  DropdownMenu,
} from '.'

import {isArray} from 'lodash'

import logo from '../assets/logo.png'

const HeaderV2 = ({
  pathname,
  search,
  organizations,
  name,
  onChangeOrganization,
  onChangePassword,
  onLogout,
  onEditProfile,
  onClickManagementDashboard,
  onClickHospitalList,
  isSystemView,
  isStaff,
  isSSOUser,
  provider,
  hasManagement,
  hasScorecardManagement,
  hasAlerts,
  hasBilling,
  hasDashboard,
  hasExploreData,
  hasInsights,
  hasPatientVolumes,
  hasGoals,
  hasHomepage,
}) => {
  const organization = useMemo(() => {
    const selectedOrganization = isArray(organizations)
      ? organizations.find(organization => organization.selected)
      : null
    return selectedOrganization ? selectedOrganization.name : null
  }, [organizations])
  const route = useMemo(() => {
    const name = pathname.split('/')[1]
    return `/${name}`
  }, [pathname])

  const {
    trackProgressMenuItems,
    analyzeDataMenuItems,
    getRecommendationMenuItems,
  } = useMemo(() => {
    const trackProgressMenuItems = []

    if (hasHomepage) {
      trackProgressMenuItems.push({name: 'Homepage', url: '/'})
    }

    if (hasGoals) {
      trackProgressMenuItems.push({name: 'Goals', url: '/goals-v2'})
    }

    const analyzeDataMenuItems = []

    if (hasDashboard) {
      analyzeDataMenuItems.push({
        name: 'Explore Summary Data',
        url: '/explore-summary-data',
      })
    }

    if (hasExploreData) {
      analyzeDataMenuItems.push({
        name: 'Explore Procedure Data',
        url: '/explore-procedure-data',
      })
    }

    if (hasPatientVolumes) {
      analyzeDataMenuItems.push({name: 'Throughput', url: '/throughput-v2'})
    }

    if (hasBilling) {
      analyzeDataMenuItems.push({name: 'Billing', url: '/billing'})
    }

    const getRecommendationMenuItems = [
      {name: 'PAC Tools', url: '/pac-tools-v2'},
    ]

    if (hasInsights) {
      getRecommendationMenuItems.unshift({
        name: 'Top Insights',
        url: '/top-insights-v2',
      })
    }

    return {
      trackProgressMenuItems,
      analyzeDataMenuItems,
      getRecommendationMenuItems,
    }
  }, [
    hasDashboard,
    hasExploreData,
    hasInsights,
    hasPatientVolumes,
    hasBilling,
    hasGoals,
    hasHomepage,
  ])

  return (
    <AgFixed
      bg="white"
      width="100%"
      height={70}
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={70}
      top={0}
      left={0}
      zIndex="lg"
    >
      <AgRow width="calc(90% - 46px)" justifyContent="space-between">
        <AgRow justifyContent="space-between">
          <AgBox mr="16px">
            <Link to="/">
              <AgImage
                height={45}
                src={logo}
                alt="Caremeasurement by Avant-garde Health"
              />
            </Link>
          </AgBox>

          <DropdownMenu
            name="Track Progress"
            id="track-progress"
            menuItems={trackProgressMenuItems}
            search={search}
          />
          <DropdownMenu
            name="Analyze Data"
            id="analyze-data"
            menuItems={analyzeDataMenuItems}
            search={search}
          />
          <DropdownMenu
            name="Get Recommendations"
            id="get-recommendations"
            menuItems={getRecommendationMenuItems}
            search={search}
          />
        </AgRow>

        <AgRow height="100%" alignItems="center" justifyContent="flex-end">
          {hasScorecardManagement && (
            <ScorecardIcon pathname={pathname} isNavV2 />
          )}
          {hasAlerts && <AlertIcon active={route === '/alerts-v2'} isNavV2 />}

          <SupportMenu
            pathname={pathname}
            search={search}
            isStaff={isStaff}
            isSystemView={isSystemView}
            isNavV2
          />
          <ProfileMenu
            organization={organization}
            name={name}
            organizations={organizations}
            onChangeOrganization={onChangeOrganization}
            onChangePassword={onChangePassword}
            onEditProfile={onEditProfile}
            onLogout={onLogout}
            onClickHospitalList={onClickHospitalList}
            onClickManagementDashboard={onClickManagementDashboard}
            showHospitalList={isStaff}
            showManagement={isStaff || hasManagement}
            isSSOUser={isSSOUser}
            provider={provider}
            isNavV2
          />
        </AgRow>
      </AgRow>
    </AgFixed>
  )
}

export default HeaderV2
