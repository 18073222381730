import React from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import isFunction from 'lodash/isFunction'
import uniqueId from 'lodash/uniqueId'
import {AgBox, AgImage, AgText, AgRow, AgColumn} from '@aghealth/ag-components'
import {RiArrowRightSLine} from 'react-icons/ri'
import {animated, useSpring, useTransition} from 'react-spring'
import colors from '../theme/colors'
import physicians from '../assets/physicians.png'
import explore from '../assets/explore.png'

const Animated = animated(AgBox)

const Link = React.memo(({name, active, onClick, url}) => {
  const animation = useSpring({
    to: {
      background: active ? colors.white : '#EEEFF0',
    },
  })
  const handleOnClick = React.useCallback(() => {
    if (isFunction(onClick)) {
      onClick({url, type: 'link'})
    }
  }, [onClick, url])
  return (
    <Animated
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      height={66}
      style={animation}
    >
      <AgText
        data-testid="link"
        color="font-primary"
        lineHeight="16px"
        fontSize="14px"
        fontWeight={active ? 'bold' : 'normal'}
        ml="32px"
        onClick={handleOnClick}
        sx={{cursor: 'pointer'}}
      >
        {name}
      </AgText>
      {active && <RiArrowRightSLine fontSize="36px" color="#1B263F" />}
    </Animated>
  )
})

Link.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

const Sublink = ({url, name, active, onClick, activeLink}) => {
  const handleOnClick = React.useCallback(() => {
    const {url: route} = activeLink
    if (isFunction(onClick)) {
      onClick({subroute: url, type: 'sublink', route})
    }
  }, [activeLink, onClick, url])
  return (
    <AgRow
      width="100%"
      height="36px"
      mr="4px"
      justifyContent="flex-start"
      alignItems="center"
    >
      <AgText
        data-testid="sublink"
        color="font-primary"
        fontSize="14px"
        lineHeight="20px"
        onClick={handleOnClick}
        fontWeight={active ? 'bold' : 'normal'}
        style={{cursor: 'pointer'}}
      >
        {name}
      </AgText>
    </AgRow>
  )
}

Sublink.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  activeLink: PropTypes.shape({
    url: PropTypes.string,
  }),
}

const Subheader = React.forwardRef(
  ({links, sublinks, isOpen, onClickLink}, ref) => {
    const transitions = useTransition(isOpen, {
      from: {opacity: 0},
      enter: {opacity: 1},
      leave: {opacity: 0},
    })
    const [_links, setLinks] = React.useState([])

    const handleOnClickLink = link => {
      setLinks(_links.map(l => ({...l, selected: l.url === link.url})))
    }

    const checkIfActive = React.useCallback(
      sublink =>
        sublink.selected &&
        _links.find(l => l.selected)?.id === links.find(l => l.selected)?.id,
      [_links, links],
    )
    const isExploreData = React.useMemo(() => _links[1]?.selected, [_links])
    React.useEffect(() => setLinks(links), [links])
    return transitions(
      (style, item) =>
        item && (
          <Animated
            ref={ref}
            data-testid="subheader"
            key={item}
            top={70}
            left="5%"
            width="90%"
            bg="white"
            height={234}
            display="flex"
            position="fixed"
            alignItems="center"
            justifyContent="space-between"
            zIndex="xxlg"
            style={style}
          >
            <AgColumn
              alignItems="center"
              justifyContent="flex-start"
              bg="#EEEFF0"
              minWidth="235px"
              height="100%"
            >
              {_links.map(l => (
                <Link
                  {...l}
                  key={l.name}
                  active={l.selected}
                  onClick={handleOnClickLink}
                />
              ))}
            </AgColumn>
            <AgRow height="87.5%" width="100%" mx="40px">
              {chunk(sublinks, 5).map(row => (
                <AgColumn
                  key={uniqueId('sublink_')}
                  sx={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  {row.map(s => (
                    <Sublink
                      {...s}
                      key={s.name}
                      activeLink={_links.find(l => l.selected)}
                      active={checkIfActive(s)}
                      onClick={onClickLink}
                    />
                  ))}
                </AgColumn>
              ))}
            </AgRow>
            <AgImage
              src={isExploreData ? explore : physicians}
              sx={{
                width: 282,
                height: 204,
                borderRadius: '4px',
                mr: '24px',
              }}
            />
          </Animated>
        ),
    )
  },
)

Subheader.propTypes = {
  links: PropTypes.array,
  sublinks: PropTypes.array,
  isOpen: PropTypes.bool,
  onClickLink: PropTypes.func,
}

export default Subheader
