import React, {Suspense} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {Logout} from '@aghealth/ag-components'
import {WindowRedirect} from '../components'
import {store} from '../store/LazyStore'
import {config} from '../store/actions'
import {withValidateDepartment, withValidateReport} from '../hocs'
import {useGoogleAnalytics} from '../hooks'
import {
  selectIsSystemView,
  selectGAUserId,
  selectRedirectTo,
  lazyRetry,
} from '../utils'
import {baseURLV1} from '../api/utils'
import {fetchSession} from '../api'
import {
  selectDepartmentSubrouteRegex,
  selectHasNavigationV2,
} from '../utils/selectors'

const Dashboard = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "dashboard-page" */
      /* webpackPrefetch: true */
      '../pages/dashboard'
    ).then(async module => {
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'dashboard-page',
)

const Scorecard = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "scorecard" */
      /* webpackPrefetch: true */
      '../pages/dashboard/containers/Scorecard'
    ).then(async module => {
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'scorecard-page',
)

const ExploreData = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "explore-data-page" */
      /* webpackPrefetch: true */
      '../pages/explore-data'
    ).then(async module => {
      const charts = await import('../store/reducers/charts').then(
        chartsModule => chartsModule.default,
      )
      store.injectReducer('charts', charts)
      return module
    }),
  'explore-data-page',
)

const SystemView = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "system-view-page" */
      /* webpackPrefetch: true */
      '../pages/system-view'
    ).then(async module => {
      const system = await import('../store/reducers/system').then(
        systemModule => systemModule.default,
      )
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('system', system)
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'system-view-page',
)

const TopInsightsv2 = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "top-insights-v2-page" */
      /* webpackPrefetch: true */
      '../pages/top-insights-v2'
    ).then(async module => {
      const insights = await import('../store/reducers/insights-v2').then(
        insightsModule => insightsModule.default,
      )
      store.injectReducer('insightsV2', insights)
      return module
    }),
  'top-insights-v2-page',
)

const TopInsightsv2ResearchSummary = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "insight-details-page-research-summary" */
      /* webpackPrefetch: true */
      '../pages/top-insights-v2/TopInsightsv2ResearchSummary'
    ).then(async module => {
      const insights = await import('../store/reducers/insights-v2').then(
        insightsModule => insightsModule.default,
      )
      store.injectReducer('insightsV2', insights)
      return module
    }),
  'insight-details-page-research-summary',
)

const TopInsight = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "insight-details-page" */
      /* webpackPrefetch: true */
      '../pages/top-insights-v2/TopInsight'
    ).then(async module => {
      const insights = await import('../store/reducers/insights-v2').then(
        insightsModule => insightsModule.default,
      )
      store.injectReducer('insightsV2', insights)
      return module
    }),
  'insight-details-page',
)

const PACTools = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "pac-tools-page" */
      /* webpackPrefetch: true */
      '../pages/pac-tools'
    ).then(async module => {
      const pac = await import('../store/reducers/pac').then(
        pacModule => pacModule.default,
      )
      store.injectReducer('pac', pac)
      return module
    }),
  'pac-tools-page',
)

const PatientVolumes = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "patient-volumes-page" */
      /* webpackPrefetch: true */
      '../pages/patient-volumes'
    ).then(async module => {
      const pacu = await import('../store/reducers/patient-volumes').then(
        pacuModule => pacuModule.default,
      )
      store.injectReducer('pacu', pacu)
      return module
    }),
  'patient-volumes-page',
)

const DataImportReport = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "data-import-report" */
      /* webpackPrefetch: true */
      '../pages/data-import-report'
    ).then(async module => {
      const report = await import('../store/reducers/report').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('report', report)
      return module
    }),
  'data-import-report-page',
)

const MiscodingTool = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "miscoding-tool" */
      /* webpackPrefetch: true */
      '../pages/miscoding-tool'
    ).then(async module => {
      const miscodings = await import('../store/reducers/miscodings').then(
        miscodingsModule => miscodingsModule.default,
      )
      store.injectReducer('miscodings', miscodings)
      return module
    }),
  'miscoding-tool-page',
)

const Goals = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "goals" */
      /* webpackPrefetch: true */
      '../pages/goals'
    ).then(async module => {
      const goals = await import('../store/reducers/goals').then(
        goalsModule => goalsModule.default,
      )
      store.injectReducer('goals', goals)
      return module
    }),
  'goals-page',
)

const GoalDetails = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "goal-details-page" */
      /* webpackPrefetch: true */
      '../pages/goals/Goal'
    ),
  'goal-details-page',
)

const KnowledgeCenter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "knowledge-center" */
      /* webpackPrefetch: true */
      '../pages/knowledge-center'
    ).then(async module => module),
  'knowledge-center-page',
)

const ContactUs = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "contact-us" */
      /* webpackPrefetch: true */
      '../pages/contact-us'
    ).then(async module => module),
  'contact-us-page',
)

const Profile = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "profile" */
      /* webpackPrefetch: true */
      '../pages/profile'
    ).then(async module => module),
  'profile-page',
)

const ChangePassword = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "change-password" */
      /* webpackPrefetch: true */
      '../pages/change-password'
    ).then(async module => module),
  'change-password-page',
)

const Iframe = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "iframe-page" */
      /* webpackPrefetch: true */
      '../pages/iframe'
    ).then(async module => {
      const response = await fetchSession()
      if (response.status === 200) return module
      return null
    }),
  'iframe-page',
)

const ScorecardManagement = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "scorecard-management" */
      /* webpackPrefetch: true */
      '../pages/scorecard-management'
    ).then(async module => {
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'scorecard-management-page',
)

const Hospitals = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "hospitals" */
      /* webpackPrefetch: true */
      '../pages/hospitals'
    ).then(async module => module),
  'hospitals-page',
)

const Alerts = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "alerts" */
      /* webpackPrefetch: true */
      '../pages/alerts'
    ).then(async module => module),
  'alerts-page',
)

const DashboardV2 = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "dashboard-v2" */
      /* webpackPrefetch: true */
      '../pages/dashboard-v2'
    ).then(async module => {
      const reducer = await import('../store/reducers/dashboard-v2').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboardV2', reducer)

      return module
    }),
  'dashboard-v2-page',
)

const iframeRoutes = [
  {title: 'Process Maps', url: '/flow', iframeSrc: '/flow/inpatient/'},
  {title: 'Personnel', url: '/personnel', iframeSrc: '/personnel/'},
  {title: 'Scenario Planning', url: '/scenario', iframeSrc: '/scenario/'},
  {title: 'Management Dashboard', url: '/manage', iframeSrc: '/manage/'},
]

const routesToRedirect = [{path: '/reset'}, {path: '/admin'}]

const LogoutContainer = () => {
  const dispatch = useDispatch()
  const handleOnMount = React.useCallback(() => {
    dispatch(config.setSkipNPIPrompt(false, {onlyLocalStorage: true}))
    dispatch(config.setSkipDeptPrompt(false, {onlyLocalStorage: true}))
  }, [dispatch])
  return <Logout onMount={handleOnMount} />
}

const LazyRoutes = () => {
  const redirectTo = useSelector(selectRedirectTo)
  const isSystemView = useSelector(selectIsSystemView)
  const hasExploreData = useSelector(state =>
    state?.config?.feature_flags?.includes(2),
  )
  const hasInsights = useSelector(state =>
    state?.config?.feature_flags?.includes(3),
  )
  const hasMiscodingTool = useSelector(state =>
    state?.config?.feature_flags?.includes(5),
  )
  const hasScorecardManagement = useSelector(state =>
    state?.config?.feature_flags?.includes(6),
  )
  const hasDashboard = useSelector(state =>
    state?.config?.feature_flags?.includes(7),
  )
  const hasPatientVolumes = useSelector(state =>
    state?.config?.feature_flags?.includes(8),
  )
  const hasAlerts = useSelector(state =>
    state?.config?.feature_flags?.includes(12),
  )
  const hasDashboardV2 = useSelector(state =>
    state?.config?.feature_flags?.includes(13),
  )
  const hasGoals = useSelector(state =>
    state?.config?.feature_flags?.includes(14),
  )
  const hasNavigationV2 = useSelector(selectHasNavigationV2)

  const id = useSelector(selectGAUserId)
  const provider = useSelector(state => state.config.user.provider)
  const departmentSubrouteRegex = useSelector(selectDepartmentSubrouteRegex)

  useGoogleAnalytics(id, window.location.pathname)
  return (
    <Suspense fallback={null}>
      <Switch>
        {/*TODO: Remove old link */}
        {hasDashboard && (
          <Route
            path="/explore-summary-data"
            component={isSystemView ? SystemView : Dashboard}
          />
        )}
        {hasDashboard && (
          <Route
            path="/dashboard/:department"
            component={withValidateDepartment(
              isSystemView ? SystemView : Dashboard,
            )}
          />
        )}
        {hasExploreData && (
          <Route path="/explore-procedure-data" component={ExploreData} />
        )}
        {hasExploreData && (
          <Route
            path="/explore-data/:department"
            component={withValidateDepartment(ExploreData)}
          />
        )}

        {hasInsights && (
          <Route
            path="/top-insights-v2/:department/:insightId/actionable-insights/research-summary/:researchSummaryId"
            component={withValidateDepartment(TopInsightsv2ResearchSummary)}
          />
        )}

        {hasInsights && hasNavigationV2 && (
          <Route
            path="/top-insights-v2/:insightId/actionable-insights/research-summary/:researchSummaryId"
            component={TopInsightsv2ResearchSummary}
          />
        )}
        {hasInsights && (
          <Route
            path="/top-insights-v2/:department/:id"
            component={withValidateDepartment(TopInsight)}
          />
        )}

        {hasInsights && (
          <Route
            exact
            path={`/top-insights-v2/:department(${departmentSubrouteRegex})`}
            component={TopInsightsv2}
          />
        )}
        {hasInsights && hasNavigationV2 && (
          <Route path="/top-insights-v2/:id" component={TopInsight} />
        )}
        {hasInsights && (
          <Route exact path="/top-insights-v2/" component={TopInsightsv2} />
        )}
        <Route path="/pac-tools" component={PACTools} />
        {hasNavigationV2 && <Route path="/pac-tools-v2" component={PACTools} />}

        {hasPatientVolumes && (
          <Route path="/throughput" component={PatientVolumes} />
        )}
        {hasNavigationV2 && hasPatientVolumes && (
          <Route path="/throughput-v2" component={PatientVolumes} />
        )}
        <Route
          path="/data-import-report/:report"
          component={withValidateReport(DataImportReport)}
        />
        {hasMiscodingTool && <Route path="/coding" component={MiscodingTool} />}
        {hasNavigationV2 && hasMiscodingTool && (
          <Route path="/billing" component={MiscodingTool} />
        )}
        <Route path="/knowledge-center/:videoId" component={KnowledgeCenter} />
        <Route path="/knowledge-center" component={KnowledgeCenter} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/profile" component={Profile} />
        <Route path="/hospitals" component={Hospitals} />
        {hasAlerts && <Route exact path="/alerts" component={Alerts} />}
        {hasNavigationV2 && hasAlerts && (
          <Route exact path="/alerts-v2" component={Alerts} />
        )}
        {hasDashboardV2 && <Route exact path="/" component={DashboardV2} />}

        {!provider && (
          <Route path="/change-password" component={ChangePassword} />
        )}
        <Route path="/logout" component={LogoutContainer} />
        {hasScorecardManagement && (
          <Route path="/scorecard-management" component={ScorecardManagement} />
        )}
        {hasDashboard && (
          <Route exact path="/scorecard" component={Scorecard} />
        )}
        {hasGoals && <Route path="/goals/:id" component={GoalDetails} />}
        {hasGoals && <Route path="/goals" component={Goals} />}
        {hasNavigationV2 && hasGoals && (
          <Route path="/goals-v2/:id" component={GoalDetails} />
        )}
        {hasNavigationV2 && hasGoals && (
          <Route path="/goals-v2" component={Goals} />
        )}
        {iframeRoutes.map(route =>
          isSystemView ? (
            !['Process Maps', 'Personnel'].includes(route.title) && (
              <Route
                path={route.url}
                key={route.url}
                component={() => (
                  <Iframe
                    title={route.title}
                    url={route.url}
                    iframeSrc={route.iframeSrc}
                  />
                )}
              />
            )
          ) : (
            <Route
              path={route.url}
              key={route.url}
              component={() => (
                <Iframe
                  title={route.title}
                  url={route.url}
                  iframeSrc={route.iframeSrc}
                />
              )}
            />
          ),
        )}
        {routesToRedirect.map(route => (
          <Route
            path={route.path}
            key={route.path}
            component={() => {
              const redirectToV1 = `${baseURLV1}${route.path}?next=${window.location.origin}`
              return <WindowRedirect url={redirectToV1} />
            }}
          />
        ))}
        <Route path="*">
          <Redirect to={redirectTo} />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default LazyRoutes
