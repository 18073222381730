import React from 'react'
import ReactGA from 'react-ga'

const useGoogleAnalytics = (userId, pathname) => {
  React.useEffect(() => {
    if (userId) {
      ReactGA.initialize('UA-66118546-8', {
        debug: true,
        titleCase: false,
      })
      ReactGA.set({userId})
    }
  }, [userId])
  React.useEffect(() => {
    if (userId) {
      ReactGA.pageview(pathname)
    }
  }, [pathname, userId])
}

export default useGoogleAnalytics
