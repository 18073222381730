import React from 'react'
import PropTypes from 'prop-types'
import {AgColumn, AgButton} from '@aghealth/ag-components'
import MessageHtmlContent from './MessageHtmlContent'

const noop = () => {}

export const MessageResponseCards = ({
  cards,
  onClick,
  disabled,
  onClickLink,
}) => {
  return cards.map(card => (
    <AgColumn key={card.title}>
      <MessageHtmlContent
        html={card.title}
        onClickLink={onClickLink}
        sx={{
          bg: 'new-gray.100',
          padding: '10px 14px',
          borderRadius: '8px',
        }}
      />
      <AgColumn>
        {card.buttons.map(button => (
          <AgButton
            disabled={disabled}
            key={button.text}
            sx={{
              cursor: disabled ? 'default' : 'pointer',
              fontSize: 'sm',
              lineHeight: 'sm',
              fontWeight: 500,
              bg: 'base.white',
              color: disabled ? 'new-gray.200' : 'new-gray.700',
              border: 'base',
              borderColor: disabled ? 'new-gray.200' : 'new-gray.300',
              borderRadius: '8px',
              boxShadow: 'xs',
              textAlign: 'left',
              padding: '10px 14px',
              mt: '6px',

              '&:hover': {
                bg: disabled ? 'base.white' : 'new-gray.50',
              },
            }}
            value={button.value}
            onClick={disabled ? noop : onClick}
          >
            {button.text}
          </AgButton>
        ))}
      </AgColumn>
    </AgColumn>
  ))
}

MessageResponseCards.displayName = 'Message.ResponseCards'

MessageResponseCards.propTypes = {
  cards: PropTypes.array,
  disabled: PropTypes.bool,
}

MessageResponseCards.defaultProps = {
  cards: [],
}

export default MessageResponseCards
