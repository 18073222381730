import omit from 'lodash/omit'
import {format} from '../format'
import {orderList} from '../../../../utils'

export const getChartFormat = config => {
  return config.chart.dimension === 'y'
    ? config.chart.left_axis
      ? config.chart.left_axis.tick_format
      : null
    : config.chart.bottom_axis
    ? config.chart.bottom_axis.tick_format
    : null
}

export const createKeysFromOptions = config =>
  config.options.values.filter(v => v.selected).map(value => value.key)

export const createLegendItems = config => {
  const items = config.options
    ? orderList(
        config.options.values
          .filter(v => v.selected)
          .map(v => ({
            id: v.key,
            label: v.title,
            color: v.color,
          })),
        'label',
        config,
      )
    : config.chart.legend

  return items
}

export const computeBandLabelKey = config =>
  config.chart.dimension === 'x'
    ? !config.chart.left_axis.tick_label_accessor
      ? config.chart.y_accessor
      : config.chart.left_axis.tick_label_accessor
    : !config.chart.bottom_axis.tick_label_accessor
    ? config.chart.x_accessor
    : config.chart.bottom_axis.tick_label_accessor

export const computeBandSublabelKey = config =>
  config.chart.dimension === 'x'
    ? config.chart.left_axis.tick_sublabel_accessor
    : config.chart.bottom_axis.tick_sublabel_accessor

export const createBandAccessor = config => {
  const id_key = config.chart.key_accessor
  const label_key = computeBandLabelKey(config)
  const sublabel_key = computeBandSublabelKey(config)
  const accessor = d => ({
    id: d[id_key],
    label: format(d[label_key], label_key),
    sublabel: format(d[sublabel_key], sublabel_key),
  })
  return accessor
}

export const createFormatter = type => value => format(value, type)

const hasEveryOptionSelected = config => {
  if (!config.options) return true
  return config.options.values.every(v => v.selected)
}

export const hasPercentiles = config =>
  config.percentiles &&
  config.data.length &&
  config.showPercentiles &&
  hasEveryOptionSelected(config)

export const createFormatTick = (config, axis) =>
  config.chart[axis]?.tick_format
    ? createFormatter(config.chart[axis].tick_format)
    : undefined

export const createAxisStyles = config => ({
  Line: {
    opacity: !config.data?.length ? 0 : 1,
  },
  GridLine: {
    strokeWidth: 0.5,
    fill: 'box-border-gray',
    opacity: !config.data?.length ? 0 : 0.2,
  },
  Label: {
    fill: '#666666',
  },
  Tick: {
    TickLine: {
      opacity: !config.data?.length ? 0 : 1,
    },
    Label: {
      fill: 'font-primary',
      color: 'font-primary',
      textAlign: 'left',
    },
    Sublabel:
      config.id === 48
        ? {
            color: 'link-blue',
            cursor: 'pointer',
            textDecoration: 'underline',
          }
        : [4].includes(config?.variant_id)
        ? {
            fill: '#9CACBC',
          }
        : {
            fill: 'font-secondary',
          },
  },
})

export const computeDisplayKeys = (config, data) => {
  const set = new Set()
  data.forEach(d => {
    const keys = Object.keys(omit(d, [config.chart.x_scale.key]))
    keys.forEach(key => {
      set.add(key)
    })
  })
  let newKeys = [...set]
  return orderList(newKeys, d => d, config)
}
