import React from 'react'
import PropTypes from 'prop-types'
import {
  AgBox,
  AgButton,
  AgText,
  AgRow,
  RefreshCcw01 as Refresh,
  X as Close,
  MessageChatCircle,
  Download02 as Download,
} from '@aghealth/ag-components'
import {useSpring, useSpringRef, useChain} from 'react-spring'
import format from 'date-fns/format'
import isDate from 'date-fns/isDate'
import MainContainer from './MainContainer'
import ChatContainer from './ChatContainer'
import MessageList from './MessageList'
import Message from './Message'
import MessageInput from './MessageInput'
import ConversationHeader from './ConversationHeader'
import TypingIndicator from './TypingIndicator'
import ToggleSwitch from '../ToggleSwitch'
import './styles.css'
import Avatar from './Avatar'
import cmavatar from '../../assets/cm-avatar.png'

export const colors = {
  on: '#A0D4BC',
  off: '#666666',
}

const ToggleSwitchStyles = {
  Root: {
    height: '16px',
    borderRadius: '10px',
    width: '45px',
    opacity: (_, props) => (props.disabled ? 0.4 : 1),
    bg: (_, props) => (props.isOn ? colors.on : colors.off),
  },
  Button: {
    cursor: (_, props) => (props.disabled ? 'default' : 'pointer'),
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    bg: (_, props) => (props.isOn ? '#129459' : '#F0F0F0'),
    right: (_, props) => (!props.isOn ? undefined : '-2px'),
    left: (_, props) => (!props.isOn ? '-0.5px' : undefined),
    top: '-5px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
  },
}

const Toggle = ({on, onClick}) => (
  <AgRow mr={2}>
    <AgText
      mr={1}
      fontSize="14px"
      lineHeight="16px"
      fontWeight={!on ? 'bold' : 'normal'}
    >
      V1
    </AgText>
    <ToggleSwitch onClick={onClick} styles={ToggleSwitchStyles} on={on} />
    <AgText
      ml={1}
      fontSize="14px"
      lineHeight="16px"
      fontWeight={on ? 'bold' : 'normal'}
    >
      V2
    </AgText>
  </AgRow>
)

Toggle.propTypes = {
  on: PropTypes.bool,
  onClick: PropTypes.func,
}

const Trigger = props => (
  <AgButton
    {...props}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      size: 80,
      cursor: 'pointer',
      bg: 'green.100',
      borderRadius: 'circle',
      boxShadow: '2xl',
      border: '12px solid',
      borderColor: 'green.50',
    }}
  >
    <MessageChatCircle
      viewBox="0 0 22 24"
      size={36}
      sx={{
        color: 'green.500',
        '& path': {
          strokeWidth: 1.75,
        },
      }}
    />
  </AgButton>
)

const Chatbox = ({
  messages,
  onSend,
  name,
  isProcessing,
  onRefresh,
  onClose,
  textInputPlaceholder,
  onDownload,
  onClickLink,
  onSendMessageFeedback,
  stepId,
}) => {
  const [show, setShow] = React.useState(true)
  const containerRef = useSpringRef()
  const containerSpring = useSpring({
    ref: containerRef,
    from: {
      width: '80px',
      height: '80px',
      borderRadius: '12px',
    },
    to: show
      ? {
          width: '550px',
          height: '100%',
          minHeight: '80px',
          borderRadius: '12px',
        }
      : {
          width: '80px',
          height: '80px',
          borderRadius: '12px',
        },

    config: {duration: 300, stiffness: 100, mass: 1, damping: 10},
    immediate: false,
  })

  const headerRef = useSpringRef()
  const headerSpring = useSpring({
    ref: headerRef,
    from: {
      opacity: 0,
      height: '97px',
    },
    to: show ? {opacity: 1, height: '97px'} : {opacity: 0, height: '0px'},
    config: {duration: 300, stiffness: 100, mass: 1, damping: 10},
    immediate: false,
  })

  const inputRef = useSpringRef()
  const inputSpring = useSpring({
    ref: inputRef,
    from: {
      opacity: 0,
      height: '88px',
    },
    to: show ? {opacity: 1, height: '88px'} : {opacity: 0, height: '0px'},
    config: {duration: 300, stiffness: 100, mass: 1, damping: 10},
    immediate: false,
  })

  const listRef = useSpringRef()
  const listSpring = useSpring({
    ref: listRef,
    from: {
      height: '0px',
      width: '100%',
    },
    to: show
      ? {
          height: '400px',
          width: '100%',
        }
      : {
          height: '0px',
          width: '100%',
        },
    config: {duration: 300, stiffness: 100, mass: 1, damping: 10},
    immediate: false,
  })

  useChain(
    show
      ? [containerRef, headerRef, inputRef, listRef]
      : [listRef, inputRef, headerRef, containerRef],
    show ? [0, 0.25, 0.25, 0.75] : [0, 1, 1, 1],
  )

  const handleOnClose = React.useCallback(() => {
    setShow(false)

    setTimeout(onClose, 1150)
  }, [onClose])

  const handleOnClickButton = React.useCallback(
    e => {
      e.preventDefault()
      onSend(e.target.value)
    },
    [onSend],
  )

  const getMessagePerStepId = number => {
    switch (number) {
      case 1:
        return 'Okay! Interpreting your question'
      case 2:
        return 'Locating the data to answer your question'
      case 3:
        return 'Found it! Calculating'
      case 4:
        return 'Summarizing your information'
      default:
        return 'Okay! Interpreting your question'
    }
  }

  const Indicator = React.useMemo(() => {
    const content = isProcessing ? getMessagePerStepId(stepId) : null
    return () => (content ? <TypingIndicator content={content} /> : null)
  }, [isProcessing, stepId])

  return (
    <MainContainer
      sx={{
        overflow: 'hidden',
        background: 'white',
        boxShadow: '3xl',
        border: 'none',
      }}
      style={containerSpring}
    >
      <ChatContainer>
        <ConversationHeader
          style={headerSpring}
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: '24px',
            bg: 'white',
            borderBottom: 'base',
            borderColor: 'new-gray.200',
          }}
        >
          <Avatar
            sx={{size: '56px', minWidth: '56px', minHeight: '56px'}}
            src={cmavatar}
          />
          <ConversationHeader.Content>
            <AgText
              sx={{
                variant: 'texts.lg-semibold',
                color: 'new-gray.900',
                ml: '8px',
              }}
            >
              {name}
            </AgText>
          </ConversationHeader.Content>
          <ConversationHeader.Actions>
            <Download
              onClick={onDownload}
              sx={{
                cursor: 'pointer',
                color: 'new-gray.400',
              }}
            />
            <Refresh
              onClick={onRefresh}
              sx={{
                cursor: 'pointer',
                color: 'new-gray.400',
                mx: '8px',
              }}
            />
            <Close
              onClick={handleOnClose}
              viewBox="4 4 16 16"
              sx={{
                cursor: 'pointer',
                color: 'new-gray.400',
                '& > path': {
                  strokeWidth: 1.5,
                },
              }}
            />
          </ConversationHeader.Actions>
        </ConversationHeader>
        <MessageList style={listSpring} typingIndicator={<Indicator />}>
          {messages?.map((message, index) => (
            <Message
              key={message.id}
              model={{
                id: message.id,
                type: message.responseCards ? 'response-cards' : 'html',
                message: message.responseCards
                  ? message.responseCards
                  : message.text,
                sentTime: message.date.toISOString(),
                sender: message.type,
                feedbackId: message.feedbackId,
                isPositive: message.isPositive,
                isLastMessageInGroup: message.isLastMessageInGroup,
              }}
              sx={{
                mt: index > 0 ? '16px' : 0,
                mb: index === messages.length - 1 ? '16px' : 0,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                width: '100%',
              }}
              onClickButton={handleOnClickButton}
              onClickLink={onClickLink}
              onSendFeedback={onSendMessageFeedback}
            >
              {message.type === 'bot' ? (
                <Avatar
                  src={cmavatar}
                  sx={{
                    size: '40px',
                    minWidth: '40px',
                    minHeight: '40px',
                    marginTop: '8px',
                  }}
                />
              ) : null}
              <Message.Header
                sender={message.type === 'bot' ? 'Sid' : 'You'}
                sentTime={
                  isDate(message.date) ? format(message.date, 'EEEE h:mma') : ''
                }
              />
            </Message>
          ))}
        </MessageList>
        <MessageInput
          placeholder={textInputPlaceholder}
          attachButton={false}
          onSend={onSend}
          disabled={isProcessing}
          style={inputSpring}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
            px: '24px',
            borderTop: 'base',
            borderColor: 'new-gray.200',
          }}
        />
      </ChatContainer>
    </MainContainer>
  )
}

Chatbox.propTypes = {
  messages: PropTypes.array,
  onSend: PropTypes.func,
  name: PropTypes.string,
  isProcessing: PropTypes.bool,
  onRefresh: PropTypes.func,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  textInputPlaceholder: PropTypes.string,
  onClickLink: PropTypes.func,
  onSendMessageFeedback: PropTypes.func,
  stepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const Chatbot = ({
  show,
  messages,
  onSend,
  name,
  isProcessing,
  onRefresh,
  onClose,
  onShow,
  onDownload,
  textInputPlaceholder,
  onClickLink,
  onSendMessageFeedback,
  stepId,
}) => {
  const handleOnSend = React.useCallback(
    text => {
      onSend(text)
    },
    [onSend],
  )

  return (
    <AgBox
      display="block"
      sx={{
        position: 'fixed',
        bottom: 50,
        right: 'calc(5% - 66px)',
        zIndex: 50000,
        mb: '15px',
      }}
    >
      {show ? (
        <Chatbox
          messages={messages}
          onSend={handleOnSend}
          name={name}
          isProcessing={isProcessing}
          onRefresh={onRefresh}
          onClose={onClose}
          onDownload={onDownload}
          textInputPlaceholder={textInputPlaceholder}
          onClickLink={onClickLink}
          onSendMessageFeedback={onSendMessageFeedback}
          stepId={stepId}
        />
      ) : (
        <Trigger onClick={onShow} />
      )}
    </AgBox>
  )
}

Chatbot.propTypes = {
  show: PropTypes.bool,
  messages: PropTypes.array,
  onSend: PropTypes.func,
  name: PropTypes.string,
  isProcessing: PropTypes.bool,
  onShow: PropTypes.func,
  onRefresh: PropTypes.func,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  textInputPlaceholder: PropTypes.string,
  onClickLink: PropTypes.func,
  onSendMessageFeedback: PropTypes.func,
  stepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Chatbot
