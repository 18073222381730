import {
  toJson,
  withMassageFilters,
  baseURL,
  getHeaders,
  withChartsConfig,
} from './utils'
import {toFilteredQueryString} from '../utils'

const endpoint = `${baseURL}/api/charts`

const _fetchCharts = withChartsConfig(
  toJson(async options => {
    const {search: _search, signal} = options
    const search = toFilteredQueryString(_search, [
      'supply_category_names',
      'surgeon_ids',
    ])
    return fetch(endpoint.concat(search), {headers: await getHeaders(), signal})
  }),
)

export const fetchHighVolumeProcedures = toJson(async options => {
  const {search, signal} = options
  const url = `${endpoint}/high-volume-procedure`
  return fetch(url.concat(search), {headers: await getHeaders(), signal})
})

export const fetchHospitalCaseNumbers = toJson(async options => {
  const {search, signal} = options
  const url = `${endpoint}/hospital-case-numbers`
  return fetch(url.concat(search), {headers: await getHeaders(), signal})
})

export const fetchSupplyNames = toJson(async options => {
  const {search, signal} = options
  const url = `${endpoint}/supply/products`
  return fetch(url.concat(search), {
    signal,
    headers: await getHeaders(),
  })
})

export const fetchSupplyManufacturerNumbers = toJson(async options => {
  const {search, signal} = options
  const url = `${endpoint}/supply/manufacturer-numbers`
  return fetch(url.concat(search), {
    signal,
    headers: await getHeaders(),
  })
})

export const fetchProductsByPatientId = toJson(async options => {
  const {patient_id, signal} = options
  const url = `${endpoint}/patient/${patient_id}/products`
  return fetch(url, {
    signal,
    headers: await getHeaders(),
  })
})

export const fetchExport = async options => {
  const {search} = options
  const url = `/api/charts/export${search}`
  const result = await fetch(url, {
    headers: await getHeaders(),
  })
  const blob = await result.blob()
  return blob
}

export const fetchCharts = withMassageFilters(_fetchCharts)
