import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isPlainObject from 'lodash/isPlainObject'
import {AgBox, AgButton} from '@aghealth/ag-components'
import {Link} from 'react-router-dom'
import parse from 'html-react-parser'
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import {prefix} from './settings'

function isHtml(str) {
  return /<\/?[a-z][\s\S]*>/i.test(str)
}

function isOnClickLink(href) {
  const url = new URL(href)
  const page = url.pathname.split('/')[1]
  return page === 'dashboard' || page === 'explore-data'
}

function createLinkPayload(href) {
  const url = new URL(href)
  const route = url.pathname.split('/')[1]
  const subroute = url.pathname.split('/')[2]
  return {
    route: '/' + route,
    subroute: subroute ? '/' + subroute : undefined,
    search: url.search,
  }
}

export const MessageHtmlContent = ({html, className, onClickLink, sx}) => {
  const cName = `${prefix}-message__html-content`

  const options = {
    replace: ({attribs, children}) => {
      if (!attribs) return

      if (attribs.href) {
        // replace <a> with <Link>
        const href = attribs.href
        const linkChildren = children.map(child =>
          child.type === 'text' ? child.data : child,
        )
        if (isOnClickLink(href)) {
          const payload = createLinkPayload(href)
          return (
            <AgButton
              onClick={() => onClickLink(payload)}
              sx={{
                background: 'none',
                border: 'none',
                padding: 0,
                margin: 0,
                fontFamily: 'inherit',
                fontSize: 'inherit',
                color: '#007BFF !important',
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {linkChildren}
            </AgButton>
          )
        } else if (attribs.class && attribs.class.includes('sql')) {
          // Handle SQL code blocks
          const sqlText = children[0].data
          return (
            <SyntaxHighlighter language="sql" style={docco}>
              {sqlText}
            </SyntaxHighlighter>
          )
        } else {
          const to = new URL(href).pathname + new URL(href).search
          return (
            <Link to={to} style={{color: '#007BFF'}}>
              {linkChildren}
            </Link>
          )
        }
      }
    },
  }

  const _html = html?.replace(
    /```sql\n([\s\S]*?)\n```/g,
    (match, p1) => `<code class="sql">${p1}</code>`,
  )

  const formatted = _html?.split('\n').reduce((acc, next) => {
    if (isHtml(next)) {
      return acc + next
    } else {
      return acc + `<p>${next?.trim()}</p>`
    }
  }, '')

  const parsed = parse(formatted, options)

  const jsx = Array.isArray(parsed)
    ? parsed?.filter(p => isPlainObject(p))
    : parsed

  return (
    <AgBox className={classNames(cName, className)} sx={sx}>
      {jsx}
    </AgBox>
  )
}

MessageHtmlContent.displayName = 'Message.HtmlContent'

MessageHtmlContent.propTypes = {
  /**
   * Html string will be rendered in component using dangerouslySetInnerHTML
   */
  html: PropTypes.string,

  /** Additional classes. */
  className: PropTypes.string,
  onClickLink: PropTypes.func,
  sx: PropTypes.object,
}

MessageHtmlContent.defaultProps = {
  onClickLink: () => {},
  sx: {},
}

export default MessageHtmlContent
