/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import size from 'lodash/size'
import sum from 'lodash/sum'
import orderBy from 'lodash/orderBy'
import times from 'lodash/times'
import isNaN from 'lodash/isNaN'
import isObject from 'lodash/isPlainObject'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import {createSelector} from 'reselect'
import {format} from './format'
import {fromQueryString} from '../../../utils'

const computeWeightedAverage = (d, key, total) =>
  (d.case_count / total) * d[key]

const getDigitsAmtFromKey = key => {
  if (key === 'risk_score') return 4
  return 1
}

const toDigits = (num, key) => {
  if (!isNumber(num) || isNaN(num)) return null
  const amt = getDigitsAmtFromKey(key)
  const str = num.toFixed(amt)
  const result = Number(str)
  return result
}

export const excludeMedicareData = data => {
  const newData = []
  data.forEach(d => {
    if (d.insufficient_data) {
      newData.push(
        omit(d, [
          'home_health_agency_spend',
          'inpatient_spend',
          'medicare_part_b_spend',
          'other_outpatient_spend',
          'readmission_spend',
          'skilled_nursing_facility_spend',
          'total_spend',
          'readmission_count',
          'readmission_percent',
          'complication_count',
          'complication_percent',
        ]),
      )
    } else {
      newData.push(d)
    }
  })
  return newData
}

export const withWeightedAverages = (data, key) => {
  if (!key) return data
  const hasAvg = data?.some(d => d.id === -1)
  const onlyAvg = data?.every(d => d.id === -1)
  if (onlyAvg) return data
  if (!hasAvg) return data
  const non_averages = data.filter(d => ![-1].includes(d.id))
  const total_case_count = sum(non_averages.map(d => d.case_count))
  if (key === 'case_count') {
    const updated = data.map(d => {
      if (d.id === -1) return {...d, case_count: total_case_count}
      return d
    })
    return updated
  }
  const raw_value = non_averages.reduce(
    (acc, d) =>
      isArray(acc)
        ? acc.map(
            (prev, i) =>
              prev + computeWeightedAverage(d, key[i], total_case_count),
          )
        : acc + computeWeightedAverage(d, key, total_case_count),
    isArray(key) ? times(key.length, () => 0) : 0,
  )
  const value = isArray(raw_value)
    ? raw_value.map((v, i) => toDigits(v, key[i]))
    : toDigits(raw_value, key)

  const payload = isArray(key)
    ? key.reduce((acc, k, i) => {
        const val = value[i]
        const result = {...acc, [k]: val}
        return result
      }, {})
    : {[key]: value}
  return data.map(d => {
    if (d.id === -1) {
      return {
        ...d,
        case_count: total_case_count,
        ...payload,
      }
    }
    return d
  })
}

export const toggleName = show => physician => ({
  ...physician,
  surgeon_name:
    physician.insufficient_data && show
      ? physician.show_name_asterisk
      : physician.insufficient_data && !show
      ? physician.hide_name_asterisk
      : show
      ? physician.show_name
      : physician.hide_name,
})

const computeLineChartName = ({id, physician, show, newData}) =>
  [29, 8, 11].includes(id) &&
  newData.find(item => item.name === physician.name)?.asterisk &&
  show
    ? physician.show_name_asterisk
    : [29, 8, 11].includes(id) &&
      newData.find(item => item.name === physician.name)?.asterisk &&
      !show
    ? physician.hide_name_asterisk
    : show
    ? physician.show_name
    : physician.hide_name

export const withToggleLineChartNames = (config, data, show, newData) =>
  data.map(d => ({
    ...d,
    surgeon_name: computeLineChartName({
      id: config.id,
      physician: d,
      show,
      newData,
    }),
  }))

export const filterByCache = cache => item => !!cache[item.id]

export const isPhysicianOverTimeChart = config =>
  (config.chart &&
    config.chart.type === 2 &&
    config.chart.y_scale?.group_key === 'surgeon_name') ||
  config.chart.y_scale?.group_key === 'organization_full_name'

export const getChartMetricKey = config => {
  if (!config.chart) return null
  if (config.chart.type === 1) {
    return config.chart.group_mode === 'stacked'
      ? config.chart.max_value_accessor
        ? config.options
          ? config.options.values
              .filter(v => v.selected)
              .map(v => v.key)
              .concat([config.chart.max_value_accessor])
          : [...config.chart.keys, config.chart.max_value_accessor]
        : config.options
        ? config.options.values.filter(v => v.selected).map(v => v.key)
        : config.chart.keys
      : config.chart.dimension === 'y'
      ? config.chart.y_accessor
      : config.chart.x_accessor
  }
  if (config.chart.type === 2) {
    return config.chart.y_scale?.group_key === 'surgeon_name' ||
      config.chart.y_scale?.group_key === 'organization_full_name'
      ? config.chart.y_scale.keys[0]
      : null
  }
  return null
}

const selectPhysiciansCache = createSelector(
  state => state.charts.config.physicians,
  physicians =>
    physicians
      ? physicians
          .filter(p => p.selected)
          .reduce((acc, p) => {
            acc[p.id] = p
            return acc
          }, {})
      : null,
)

const selectOrganizationsCache = createSelector(
  state => state.charts.config.organizations,
  organizations =>
    organizations
      ? organizations
          .filter(o => o.selected)
          .reduce((acc, o) => {
            acc[o.id] = o
            return acc
          }, {})
      : null,
)

const add = (a, b) => {
  const _a = isNumber(a) ? a : 0
  const _b = isNumber(b) ? b : 0
  return _a + _b
}

export const sumKeys = (d, keys) => keys.reduce((acc, k) => add(acc, d[k]), 0)

export const selectData = createSelector(
  state => state.charts.show_physician_names,
  state => state.charts.show_all_case_supply_costs,
  selectPhysiciansCache,
  state => state.charts.config,
  state => state.charts.data,
  state => state.charts.deleted,
  selectOrganizationsCache,
  (
    show_names,
    show_all_supply_costs,
    physicians,
    config,
    _data,
    deleted,
    organizations,
  ) => {
    let data = _data
    const key = getChartMetricKey(config)

    if (physicians) {
      const filtered = data.filter(filterByCache(physicians))
      let result
      if (isPhysicianOverTimeChart(config)) {
        const group = groupBy(filtered, d => d.date_of_surgery)
        result = Object.values(group).reduce(
          (acc, value) => [...acc, ...withWeightedAverages(value, key)],
          [],
        )
      } else {
        result = withWeightedAverages(filtered, key)
      }
      if (config.chart && config.chart.type === 1) {
        data = result.map(toggleName(show_names))
      } else {
        const newData = []
        Object.keys(physicians).forEach(p => {
          if (
            physicians[p].case_count < 11 ||
            physicians[p].insufficient_data
          ) {
            const newItem = {
              name: physicians[p].surgeon_name,
              asterisk: true,
            }
            newData.push(newItem)
          } else {
            const newItem = {
              name: physicians[p].surgeon_name,
              asterisk: false,
            }
            newData.push(newItem)
          }
        })
        data = withToggleLineChartNames(config, result, show_names, newData)
      }
    }
    if (organizations) {
      const filtered = data.filter(filterByCache(organizations))
      let result
      if (isPhysicianOverTimeChart(config)) {
        const group = groupBy(filtered, d => d.date_of_surgery)
        result = Object.values(group).reduce(
          (acc, value) => [...acc, ...withWeightedAverages(value, key)],
          [],
        )
      } else {
        result = withWeightedAverages(filtered, key)
      }
      data = result
    }
    if (config.id === 48) {
      const removed = data.filter(d => !deleted[d[config.chart.key_accessor]])
      const selected = config.options.values.filter(v => v.selected)
      const newData = orderBy(
        removed
          .filter(d => selected.some(v => v.selected && !!d[v.key]))
          .map(d => ({
            ...d,
            selected_total: sumKeys(
              d,
              selected.map(v => v.key),
            ),
          })),
        show_all_supply_costs ? 'total_cost' : 'selected_total',
        'desc',
      )
      data = newData
    }
    if (data?.some(item => item.insufficient_data)) {
      data = excludeMedicareData(data)
    }
    if (config?.chart?.max_value_accessor && [4].includes(config?.variant_id)) {
      data = orderBy(
        data,
        d =>
          d.id === -1
            ? -Infinity
            : isNumber(d[config.chart.max_value_accessor])
            ? d[config.chart.max_value_accessor]
            : -Infinity,
        'desc',
      )
    }
    if (
      data?.some(d => isNumber(d?.case_count)) &&
      [1].includes(config?.variant_id)
    ) {
      data = orderBy(
        data,
        d =>
          d.id === -1
            ? -Infinity
            : isNumber(d.case_count)
            ? d.case_count
            : -Infinity,
        'desc',
      )
    }

    return data
  },
)

export const selectConfig = createSelector(
  selectData,
  state => state.charts.config,
  state => state.charts.show_all_case_supply_costs,
  state => state.charts.hide_case_removal_buttons,
  state => state.charts.deleted,
  (
    data,
    config,
    show_all_case_supply_costs,
    hide_case_removal_buttons,
    deleted,
  ) => {
    let _config = config
    if (_config.id === 48 && show_all_case_supply_costs) {
      const set = data.reduce((set, d) => {
        Object.entries(d).forEach(([key, value]) => {
          if (isNumber(value)) {
            set.add(key)
          }
        })
        return set
      }, new Set())
      const options = {
        ..._config.options,
        values: _config.options.values.map(v => ({
          ...v,
          selected: set.has(v.key),
        })),
      }
      _config = {..._config, options}
    }
    if (_config.id === 48 && !hide_case_removal_buttons) {
      const unfiltered_data = _config.unfiltered_data.filter(
        d => !deleted[d[_config.chart.key_accessor]],
      )
      _config = {..._config, unfiltered_data}
    }
    return _config
  },
)

export const selectSelectedSupplyCategoriesIds = createSelector(
  selectConfig,
  config =>
    config?.options?.values
      ?.filter(c => c.selected)
      ?.map(i => parseInt(i.key.substring(5), 10)),
)

const selectTimePeriod = createSelector(
  state => state.charts.filters.time_periods,
  time_periods => ({
    start: time_periods.selected_start,
    end: time_periods.selected_end,
    max: time_periods.max,
    id: time_periods.values.filter(v => v.selected).map(v => v.id)[0],
    type: 'time_periods',
  }),
)

export const selectDroppedFilters = createSelector(
  selectTimePeriod,
  state => state.charts?.dropped_filters,
  (time_period, dropped) => {
    if (isEmpty(dropped)) return null
    const names = Object.entries(dropped).reduce(
      (acc, [key, values]) => [
        ...acc,
        ...values.map(v =>
          ['drgs', 'icds'].includes(key)
            ? `Inpatient: ${key === 'drgs' ? `DRG ${v.id}` : v.name}`
            : `Outpatient: ${v.name}`,
        ),
      ],
      [],
    )
    return {time_period, names}
  },
)

export const selectDeletedCount = createSelector(
  state => state?.charts?.deleted,
  deleted => (isObject(deleted) ? size(deleted) : 0),
)

export const selectMessages = createSelector(selectDroppedFilters, dropped => {
  const messages = []
  if (dropped) {
    messages.push(format(dropped, 'dropped_filters'))
  }
  return messages
})

export const selectSystemGroupByToggles = createSelector(
  state => state.charts.group_by_surgeon,
  state => state.charts.group_by_organization,
  (group_by_surgeon, group_by_organization) => {
    const items = [
      {
        id: 1,
        name: 'Group by Surgeon by Organization',
        selected: group_by_surgeon && !group_by_organization,
        type: 'group_by_surgeon_by_organization',
      },
      {
        id: 2,
        name: 'Group by Surgeon',
        selected: !group_by_surgeon && !group_by_organization,
        type: 'group_by_surgeon',
      },
      {
        id: 3,
        name: 'Group by Organization',
        selected: group_by_surgeon && group_by_organization,
        type: 'group_by_organization',
      },
    ]
    return items
  },
)

export const selectIncludeOncology = createSelector(
  state => state.charts.filters.is_oncology,
  state => state.charts.filters.exclude_oncology,
  (is_oncology, exclude_oncology) => {
    const items = [
      {
        id: 1,
        name: 'All Cases',
        selected: !is_oncology && !exclude_oncology,
        type: 'include_oncology',
      },
      {
        id: 2,
        name: 'Only Oncology',
        selected: is_oncology && !exclude_oncology,
        type: 'include_oncology',
      },
      {
        id: 3,
        name: 'Exclude Oncology',
        selected: exclude_oncology && !is_oncology,
        type: 'include_oncology',
      },
    ]
    return items
  },
)

export const selectIncludeOncologySelected = createSelector(
  selectIncludeOncology,
  state =>
    state.charts?.filters?.departments
      ?.filter(s => s.selected)
      ?.some(s => s.id === 4),
  (items, hasOncology) => {
    const selected = items.filter(item => item.selected)

    return hasOncology || selected[0]?.id === 1 ? [] : selected
  },
)

export const selectIncludeRobotics = createSelector(
  state => state.charts.filters.is_robotics,
  state => state.charts.filters.exclude_robotics,
  (is_robotics, exclude_robotics) => {
    const items = [
      {
        id: 1,
        name: 'All Cases',
        selected: !is_robotics && !exclude_robotics,
        type: 'include_robotics',
      },
      {
        id: 2,
        name: 'Only Robotics Cases',
        selected: is_robotics && !exclude_robotics,
        type: 'include_robotics',
      },
      {
        id: 3,
        name: 'Exclude Robotics Cases',
        selected: exclude_robotics && !is_robotics,
        type: 'include_robotics',
      },
    ]
    return items
  },
)

export const selectIncludeRoboticsSelected = createSelector(
  selectIncludeRobotics,
  items => {
    const selected = items.filter(item => item.selected)
    return selected[0]?.id === 1 ? [] : selected
  },
)

export const selectHospitalCaseNumbers = createSelector(
  state => state.charts.filters.hospital_case_numbers,
  hospital_case_numbers =>
    hospital_case_numbers.map(h => ({
      name: h,
      id: h,
      type: 'hospital_case_numbers',
    })),
)

export const selectSupplyManufacturerNumbers = createSelector(
  state => state.charts.filters.supply_manufacturer_numbers,
  numbers =>
    numbers.map(h => ({
      name: h,
      id: h,
      type: 'supply_manufacturer_numbers',
    })),
)

export const selectChartFilterParams = createSelector(
  state => state.config,
  state => state.subnav.subroutes.find(subroute => subroute.selected)?.param,
  (_, search) => search,
  (_, __, departmentSubRoute) => !!departmentSubRoute,
  (config, _, search) => {
    const {group_by_surgeon, department_ids} = fromQueryString(search, [
      'group_by_surgeon',
      'department_ids',
    ])
    const userOrganizations = config.user.organizations

    const isSystem = userOrganizations.find(
      organization => organization.selected && organization.system,
    )
    const selectedOrganization = userOrganizations.find(
      organization => organization.selected,
    )

    const isOncologyDepartment =
      department_ids === 4 ||
      (Array.isArray(department_ids) && department_ids.includes(4))

    const refreshDate = selectedOrganization.refresh_date

    let filterParams = isEmpty(search)
      ? `?refresh_date=${refreshDate}`
      : `${search}&refresh_date=${refreshDate}`

    if (isOncologyDepartment) {
      filterParams += `${filterParams}&is_oncology=true`
    }

    if (isSystem && group_by_surgeon == null) {
      filterParams += '&group_by_surgeon=true'
    }

    return filterParams
  },
)
