import isFunction from 'lodash/isFunction'
import {
  TOGGLE_SUBNAV_IS_OPEN,
  SET_SUBNAV_IS_NOT_OPEN,
  SET_SUBNAV_ACTIVE_ROUTE,
  SET_SUBNAV_ACTIVE_SUBROUTE,
  SET_SUBNAV_ACTIVE_ROUTE_WITH_SUBROUTE,
  SET_SUBNAV_LOADING,
  SET_SUBNAV_ROUTES,
  SET_SUBNAV_SUBROUTES,
} from '../types'
import {toUrl} from '../../utils'

const createPages = (
  hasExploreData,
  hasInsights,
  hasDashboard,
  hasMiscodingTool,
) => {
  const pages = []

  if (hasDashboard) {
    pages.push({
      id: 1,
      url: '/dashboard',
      name: 'Dashboard',
      selected: false,
      default: true,
    })
  }

  if (hasExploreData) {
    pages.push({
      id: 2,
      url: '/explore-data',
      name: 'Explore Data',
      selected: false,
      default: false,
    })
  }

  if (hasInsights) {
    pages.push({
      id: 3,
      url: '/top-insights-v2',
      name: 'Top Insights',
      selected: false,
      default: false,
    })
  }

  return pages
}

const toggleIsOpen = () => ({type: TOGGLE_SUBNAV_IS_OPEN})

const setIsNotOpen = () => ({type: SET_SUBNAV_IS_NOT_OPEN})

const setActiveRoute = (payload, callback) => (dispatch, getState) => {
  dispatch({type: SET_SUBNAV_ACTIVE_ROUTE, payload})
  if (isFunction(callback)) {
    callback(getState())
  }
}

const setActiveSubroute = (payload, callback) => (dispatch, getState) => {
  dispatch({type: SET_SUBNAV_ACTIVE_SUBROUTE, payload})
  if (isFunction(callback)) {
    callback(getState())
  }
}

const setActiveSubrouteByDepartment = department => (dispatch, getState) => {
  const subroute = getState()?.subnav?.subroutes?.find(
    subroute => String(subroute.id) === String(department.id),
  )
  if (subroute) {
    dispatch(setActiveSubroute(subroute.url))
  }
}

const setActiveRouteWithSubroute =
  (payload, callback) => (dispatch, getState) => {
    dispatch({type: SET_SUBNAV_ACTIVE_ROUTE_WITH_SUBROUTE, payload})
    if (isFunction(callback)) {
      callback(getState())
    }
  }

const setLoading = payload => ({
  type: SET_SUBNAV_LOADING,
  payload,
})

const setRoutes = payload => ({
  type: SET_SUBNAV_ROUTES,
  payload,
})

const setSubroutes = payload => ({
  type: SET_SUBNAV_SUBROUTES,
  payload,
})

const setState =
  ({pathname, initial}) =>
  (dispatch, getState) => {
    const hasExploreData = getState()?.config?.feature_flags?.includes(2)
    const hasInsights = getState()?.config?.feature_flags?.includes(3)
    const hasDashboard = getState()?.config?.feature_flags?.includes(7)
    const hasMiscodingTool = getState()?.config?.feature_flags?.includes(5)
    const pages = createPages(
      hasExploreData,
      hasInsights,
      hasDashboard,
      hasMiscodingTool,
    )
    const departments = getState()?.config?.departments?.map(d => ({
      ...d,
      url: toUrl(d.name),
      selected: false,
    }))
    const default_dept = hasDashboard
      ? departments?.filter(d => d.default === true)[0]
      : null
    const [page, department] = pathname
      .split('/')
      .slice(1)
      .map(s => '/'.concat(s))
    const route = pages.map(p => p.url).includes(page)
      ? page
      : initial && hasDashboard
      ? '/dashboard'
      : initial && !hasDashboard && hasMiscodingTool
      ? '/coding-optimization'
      : null
    const subroute = departments?.map(d => d.url).includes(department)
      ? department
      : initial
      ? default_dept?.url
      : null

    if (initial) {
      dispatch(setLoading(true))
    }
    if (route) {
      const routes = pages.map(page => {
        if (page.url === route) return {...page, selected: true}
        return {...page, selected: false}
      })
      dispatch(setRoutes(routes))
    }
    if (subroute) {
      const subroutes = departments?.map(dept => {
        if (dept.url === subroute) return {...dept, selected: true}
        return {...dept, selected: false}
      })
      dispatch(setSubroutes(subroutes))
    }
    if (initial) {
      dispatch(setLoading(false))
    }
  }

const actions = {
  toggleIsOpen,
  setIsNotOpen,
  setActiveRoute,
  setActiveSubroute,
  setActiveSubrouteByDepartment,
  setActiveRouteWithSubroute,
  setState,
}

export default actions
