import * as Sentry from '@sentry/react'
import lowerCase from 'lodash/lowerCase'
import {fetchConfig} from '../../api'
import {
  SET_CONFIG_DEPARTMENTS,
  SET_CONFIG_ERROR,
  SET_CONFIG_FEATURE_FLAGS,
  SET_CONFIG_LOADING,
  SET_CONFIG_SESSION_TIME,
  SET_CONFIG_USER,
  SET_CONFIG_SKIP_NPI_PROMPT,
  SET_CONFIG_SKIP_DEPT_PROMPT,
} from '../types'
import {selectOnlyOneByName} from '../../utils'

export const getUser =
  process.env.NODE_ENV === 'test'
    ? () => Promise.resolve({})
    : async () => require('@aghealth/ag-components').getCurrentUserInfo()

const getProvider = async () => {
  const user = await getUser()
  return user?.attributes?.identities
    ? JSON.parse(user?.attributes?.identities)[0]?.providerName
    : undefined
}

const setLoading = payload => ({type: SET_CONFIG_LOADING, payload})

const setDepartments = payload => ({type: SET_CONFIG_DEPARTMENTS, payload})

const setError = payload => ({type: SET_CONFIG_ERROR, payload})

const setFeatureFlags = payload => ({type: SET_CONFIG_FEATURE_FLAGS, payload})

const setUser = payload => ({type: SET_CONFIG_USER, payload})

const setSessionTime = payload => ({type: SET_CONFIG_SESSION_TIME, payload})

const setSkipNPIPrompt = (payload, meta) => ({
  type: SET_CONFIG_SKIP_NPI_PROMPT,
  payload,
  meta,
})

const setSkipDeptPrompt = (payload, meta) => ({
  type: SET_CONFIG_SKIP_DEPT_PROMPT,
  payload,
  meta,
})

const selectOrganization = payload => (dispatch, getState) => {
  const {organizations: current_organizations, ...rest} = getState().config.user
  const organizations = current_organizations.map(selectOnlyOneByName(payload))
  dispatch(setUser({...rest, organizations}))
}

const fetch =
  ({signal, skipLoading}) =>
  dispatch => {
    if (!skipLoading) {
      dispatch(setLoading(true))
    }

    return fetchConfig({signal})
      .then(async ({departments, feature_flags, user}) => {
        const provider = await getProvider()
        Sentry.setUser({
          email: user.email,
          username: user.username,
          id: user.id,
        })
        if (departments.length === 0) {
          dispatch(setDepartments([]))
        } else {
          const ordered = departments.map(d => ({
            ...d,
            param: d.name.split(' ').map(lowerCase).join('-'),
          }))
          dispatch(setDepartments(ordered))
        }
        dispatch(setFeatureFlags(feature_flags))
        const organizations = [
          ...user.organizations.filter(o => o.system),
          ...user.organizations.filter(o => !o.system),
        ]
        dispatch(setUser({...user, organizations, provider}))
        dispatch(setLoading(false))
      })
      .catch(error => {
        dispatch(setError(error))
        dispatch(setLoading(false))
      })
  }

const actions = {
  fetch,
  selectOrganization,
  setSessionTime,
  setSkipNPIPrompt,
  setSkipDeptPrompt,
}

export default actions
