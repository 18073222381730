import {subDays} from 'date-fns'

const options = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  timeZone: 'UTC',
}

const toDate = string => new Date(`${string}`)

const toRangelabel = (start, end) => `Date range: 
    ${toDate(start).toLocaleDateString('en-US', options)} - 
    ${toDate(end).toLocaleDateString('en-US', options)}`

const toSubDaysLabel = (max, range) =>
  `${range} Day Range: ${subDays(toDate(max), range - 1).toLocaleDateString(
    'en-US',
    options,
  )} - ${toDate(max).toLocaleDateString('en-US', options)}`

const toStartOfYearLabel = max => {
  const startOfYear = new Date(Date.UTC(new Date(max).getUTCFullYear(), 0, 1))
  return `Year to Date: ${startOfYear.toLocaleDateString(
    'en-US',
    options,
  )} - ${toDate(max).toLocaleDateString('en-US', options)}`
}

export const computeChipLabel = item => {
  if (item.type === 'icds') {
    return `Inpatient: ${item.name}`
  }
  if (item.type === 'drgs') {
    return `Inpatient: ${item.name}`
  }
  if (item.type === 'cpts' || item.type === 'procedures') {
    return `Outpatient: ${item.name}`
  }
  if (item.type === 'hospital_case_numbers') {
    return `Case Number: ${item.name}`
  }
  if (item.type === 'supply_manufacturer_numbers') {
    return `Manufacturer Number: ${item.name}`
  }
  if (item.type === 'supply_products') {
    return `Supply Name: ${item.name}`
  }
  if (item.type === 'supply_categories') {
    return `Supply Category: ${item.name}`
  }
  if (item.type === 'time_periods') {
    if (item.id === 1) return toSubDaysLabel(item.max, 30)
    if (item.id === 2) return toSubDaysLabel(item.max, 90)
    if (item.id === 3) return toSubDaysLabel(item.max, 365)
    if (item.id === 4) return toStartOfYearLabel(item.max)
    if (item.id === 5) return toRangelabel(item.start, item.end)
  }
  if (item.type === 'include_oncology') {
    if (item.id === 1) return 'All Oncology Cases'
    return `${item.name} Cases`
  }
  if (item.type === 'include_robotics') {
    if (item.id === 1) return 'All Robotics Cases'
    return `${item.name}`
  }
  return item.name
}
