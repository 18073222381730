export const SET_DASHBOARD_LOADING_FILTERS = 'SET_DASHBOARD_LOADING_FILTERS'
export const SET_DASHBOARD_FILTERS = 'SET_DASHBOARD_FILTERS'
export const SET_DASHBOARD_ERROR = 'SET_DASHBOARD_ERROR'
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const SET_DASHBOARD_LOADING_DATA = 'SET_DASHBOARD_LOADING_DATA'
export const SET_DASHBOARD_PROCEDURE_GROUPS = 'SET_DASHBOARD_PROCEDURE_GROUPS'
export const SET_DASHBOARD_METRIC_PREFERENCES =
  'SET_DASHBOARD_METRIC_PREFERENCES'
export const SET_DASHBOARD_TIME_PERIODS = 'SET_DASHBOARD_TIME_PERIODS'
export const SET_DASHBOARD_RISK_ADJUSTED = 'SET_DASHBOARD_RISK_ADJUSTED'
export const SET_DASHBOARD_DATE_SAVED = 'SET_DASHBOARD_DATE_SAVED'
export const SET_DASHBOARD_LOADING_METRIC_PREFERENCES =
  'SET_DASHBOARD_LOADING_METRIC_PREFERENCES'
export const SET_DASHBOARD_METRICS = 'SET_DASHBOARD_METRICS'
export const SET_SORT_BY = 'SET_SORT_BY'
export const SET_DASHBOARD_IS_DEPARTMENT_TREND =
  'SET_DASHBOARD_IS_DEPARTMENT_TREND'
export const SET_DASHBOARD_SURGEONS = 'SET_DASHBOARD_SURGEONS'
export const SET_DASHBOARD_FILTER_DATA_BY = 'SET_DASHBOARD_FILTER_DATA_BY'
export const SET_DASHBOARD_IS_ONCOLOGY = 'SET_DASHBOARD_IS_ONCOLOGY'
export const SET_DASHBOARD_EXCLUDE_ONCOLOGY = 'SET_DASHBOARD_EXCLUDE_ONCOLOGY'
export const SET_DASHBOARD_DEPARTMENT = 'SET_DASHBOARD_DEPARTMENT'
