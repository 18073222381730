import isArray from 'lodash/isArray'
import {createLegendItems} from '../utils'

const createLegend = (config, percentiles) => {
  const items = createLegendItems(config)
  const title = config.chart.legend_title
  const showLegend =
    !!config.chart.y_scale.group_key ||
    !!config.options ||
    (isArray(config.chart.y_scale.keys) && config.chart.y_scale.keys.length > 1)

  const legend = {
    items,
    title,
    isInteractive: true,
    styles: percentiles.styles,
    append: percentiles.append,
  }
  return {legend, showLegend}
}

export default createLegend
