import React, {Suspense} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {
  selectIsSystemView,
  selectIsPAC403,
  selectNoDepartments,
  lazyRetry,
} from '../utils'
import {SidebarBox} from '../components'
import {matchPath} from 'react-router-dom'
import {selectDepartmentSubrouteRegex} from '../utils/selectors'

const ExploreDataFilters = lazyRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "explore-data-sidebar" */
      '../pages/explore-data/containers/SidebarFilters'
    ),
  'explore-data-sidebar',
)

const SystemViewFilters = lazyRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "system-view-sidebar" */
      '../pages/system-view/containers/SidebarFilters'
    ),
  'system-view-sidebar',
)

const DashboardFilters = lazyRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "dashboard-sidebar" */
      '../pages/dashboard/containers/SidebarFilters'
    ),
  'dashboard-sidebar',
)

const PACToolsFilters = lazyRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "pac-tools-sidebar" */
      '../pages/pac-tools/containers/SidebarFilters'
    ),
  'pac-tools-sidebar',
)
const TopInsightsV2Filters = lazyRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "top-insights-v2-sidebar" */
      '../pages/top-insights-v2/containers/SidebarFilters'
    ),
  'top-insights-v2-sidebar',
)
const DataImportReportFilters = lazyRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "top-insights-sidebar" */
      '../pages/data-import-report/containers/SidebarFilters'
    ),
  'data-import-report-sidebar',
)
const MiscodingToolFilters = lazyRetry(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "miscoding-tool-sidebar" */
      '../pages/miscoding-tool/containers/SidebarFilters'
    ),
  'miscoding-tool-sidebar',
)

const filters = {
  'explore-data': ExploreDataFilters,
  'system-view': SystemViewFilters,
  dashboard: DashboardFilters,
  'pac-tools': PACToolsFilters,
  'data-import-report': DataImportReportFilters,
  'top-insights-v2': TopInsightsV2Filters,
  coding: MiscodingToolFilters,
}

const getPageName = (pathname, isSystemView) => {
  const [url] = pathname.split('/').slice(1)
  return isSystemView && url === 'dashboard' ? 'system-view' : url
}

const isInsightStandalonePage = pathname => {
  const [page, , id] = pathname.split('/').slice(1)
  if (page === 'top-insights-v2' && id) return true
  return false
}

const isNavV2InsightStandalonePage = ({pathname, departmentSubrouteRegex}) => {
  return (
    matchPath(pathname, {path: '/top-insights-v2/:id'}) &&
    !matchPath(pathname, {
      path: `/top-insights-v2/:department(${departmentSubrouteRegex})`,
    })
  )
}

const isNavV2InsightPage = pathname => {
  const [, department] = pathname.split('/').slice(1)
  return !department && pathname === '/top-insights-v2'
}

const Filters = () => {
  const isSystemView = useSelector(selectIsSystemView)
  const name = getPageName(window.location.pathname, isSystemView)
  const Filter = filters[name]
  return Filter ? <Filter /> : null
}

const SidebarFilters = ({visible}) => (
  <Suspense fallback={null}>{visible ? <Filters /> : null}</Suspense>
)

SidebarFilters.propTypes = {
  visible: PropTypes.bool,
}

const LazySidebar = () => {
  const {pathname} = useLocation()

  const isPAC403 = useSelector(selectIsPAC403)
  const noDepartments = useSelector(selectNoDepartments)
  const departmentSubrouteRegex = useSelector(selectDepartmentSubrouteRegex)

  if (
    (pathname === '/pac-tools' && isPAC403) ||
    !filters[getPageName(pathname)]
  )
    return null

  if (
    ['/dashboard', '/explore-data', '/top-insights-v2'].includes(pathname) &&
    noDepartments
  )
    return null

  if (
    isNavV2InsightPage(pathname) ||
    isInsightStandalonePage(pathname) ||
    isNavV2InsightStandalonePage({pathname, departmentSubrouteRegex})
  )
    return null

  return (
    <SidebarBox width={454}>
      <SidebarFilters />
    </SidebarBox>
  )
}

export default LazySidebar
