import React from 'react'
import {useIdleTimer} from 'react-idle-timer'
import {signOut} from '@aghealth/ag-components'
import {useDispatch} from 'react-redux'
import {fetchSession} from '../api'
import {config} from '../store/actions'

const formatTime = ms => {
  const s = Math.floor((ms / 1000) % 60)
  const m = Math.floor((ms / (60 * 1000)) % 60)
  const padSecs = sec => (sec < 10 ? `0${sec}` : sec)
  return `${m}:${padSecs(s)}`
}

const updateSession = async dispatch => {
  try {
    const result = await fetchSession()
    const json = await result.json()

    if (result.status !== 200 || json.session_cookie_age === 0) {
      dispatch(config.setSkipNPIPrompt(false, {onlyLocalStorage: true}))
      dispatch(config.setSkipDeptPrompt(false, {onlyLocalStorage: true}))
      signOut()
    }
    return json
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return null
  }
}

const useUpdateSession = () => {
  const dispatch = useDispatch()
  const {getRemainingTime} = useIdleTimer({
    timeout: 600000,
    onIdle: React.useCallback(() => {
      dispatch(config.setSkipNPIPrompt(false, {onlyLocalStorage: true}))
      dispatch(config.setSkipDeptPrompt(false, {onlyLocalStorage: true}))
      signOut()
    }, [dispatch]),
    onAction: React.useCallback(() => updateSession(dispatch), [dispatch]),
    eventsThrottle: 5000,
    debounce: 5000,
  })
  React.useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getRemainingTime()
      dispatch(config.setSessionTime(formatTime(remaining)))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [dispatch, getRemainingTime])
}

export default useUpdateSession
