import React from 'react'
import {Redirect, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectSubrouteUrls, selectRedirectTo} from '../utils'

const withValidateDepartment = Page => {
  const Container = () => {
    const {department} = useParams()
    const urls = useSelector(selectSubrouteUrls)
    const redirectTo = useSelector(selectRedirectTo)
    return urls.includes(`/${department}`) ? (
      <Page />
    ) : (
      <Redirect to={redirectTo} />
    )
  }
  return Container
}

export default withValidateDepartment
