import HeaderV2 from '../components/HeaderV2'
import {useSelector, useDispatch} from 'react-redux'
import {useLocation, useHistory} from 'react-router-dom'
import {signOut} from '@aghealth/ag-components'
import {changeOrganization} from '../api'
import {baseURL} from '../api/utils'
import {subnav, config} from '../store/actions'
import {
  selectIsSystemView,
  selectIsStaff,
  selectHasBilling,
  toCommonQueryStringV2,
  selectHasDashboardV2,
} from '../utils'
import {useCallback, useEffect, useMemo, useRef} from 'react'

const NavbarV2 = () => {
  const mounted = useRef()
  const organizations = useSelector(state => state.config?.user?.organizations)
  const name = useSelector(state => state.config?.user?.first_name)
  const isSystemView = useSelector(selectIsSystemView)
  const isStaff = useSelector(selectIsStaff)
  const hasBilling = useSelector(selectHasBilling)
  const isSSOUser = useSelector(state => state.config?.user?.sso_user)
  const provider = useSelector(state => state.config?.user?.provider)
  const hasManagement = useSelector(
    state => state.config?.user?.show_management,
  )
  const hasScorecardManagement = useSelector(state =>
    state?.config?.feature_flags?.includes(6),
  )
  const hasAlerts = useSelector(state =>
    state?.config?.feature_flags?.includes(12),
  )
  const hasExploreData = useSelector(state =>
    state?.config?.feature_flags?.includes(2),
  )
  const hasInsights = useSelector(state =>
    state?.config?.feature_flags?.includes(3),
  )
  const hasDashboard = useSelector(state =>
    state?.config?.feature_flags?.includes(7),
  )
  const hasPatientVolumes = useSelector(state =>
    state?.config?.feature_flags?.includes(8),
  )

  const hasGoals = useSelector(state =>
    state?.config?.feature_flags?.includes(14),
  )
  const hasHomepage = useSelector(selectHasDashboardV2)
  const dispatch = useDispatch()
  const {pathname, search} = useLocation()
  const commonQueryString = useMemo(
    () => toCommonQueryStringV2(search),
    [search],
  )
  const {push} = useHistory()
  const handleOnClickHospitalList = useCallback(() => {
    push('/hospitals')
  }, [push])
  const editProfile = useCallback(() => {
    push('/profile')
  }, [push])
  const handleOnChangeOrganization = useCallback(organization => {
    const body = organization.system
      ? {organization_group_id: organization.id}
      : {organization_id: organization.id}

    changeOrganization({
      body,
      onSuccess: () => {
        window.location.replace(baseURL)
      },
    })
  }, [])
  const handleOnClickManagementDashboard = useCallback(() => {
    push('/manage/')
  }, [push])
  const handleChangePassword = useCallback(() => {
    push('/change-password')
  }, [push])
  const handleLogOut = useCallback(() => {
    dispatch(config.setSkipNPIPrompt(false, {onlyLocalStorage: true}))
    dispatch(config.setSkipDeptPrompt(false, {onlyLocalStorage: true}))
    signOut()
  }, [dispatch])
  useEffect(() => {
    if (mounted.current) {
      dispatch(subnav.setState({initial: false, pathname}))
    }
    mounted.current = true
  }, [dispatch, pathname])

  return (
    <HeaderV2
      pathname={pathname}
      search={commonQueryString}
      organizations={organizations}
      name={name}
      onLogout={handleLogOut}
      onChangePassword={handleChangePassword}
      onChangeOrganization={handleOnChangeOrganization}
      onEditProfile={editProfile}
      isSystemView={isSystemView}
      onClickHospitalList={handleOnClickHospitalList}
      onClickManagementDashboard={handleOnClickManagementDashboard}
      isStaff={isStaff}
      isSSOUser={isSSOUser}
      provider={provider}
      hasManagement={hasManagement}
      hasScorecardManagement={hasScorecardManagement}
      hasAlerts={hasAlerts}
      hasBilling={hasBilling}
      hasDashboard={hasDashboard}
      hasExploreData={hasExploreData}
      hasInsights={hasInsights}
      hasPatientVolumes={hasPatientVolumes}
      hasGoals={hasGoals}
      hasHomepage={hasHomepage}
    />
  )
}

export default NavbarV2
