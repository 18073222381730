/* eslint-disable no-use-before-define */
import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import {
  createStore as _createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux'
import * as Sentry from '@sentry/react'
import {thunkMiddleware} from './middleware'
import config from './reducers/config'
import subnav from './reducers/subnav'
import chatbot from './reducers/chatbot'

const staticReducers = {
  config,
  subnav,
  chatbot,
}

export const createStore = (initialState, middlewares) => {
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer]
  const composedEnhancers = compose(...enhancers)
  if (process.env.REACT_APP_SENTRY_DSN) {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({})
    enhancers.push(sentryReduxEnhancer)
  }
  const store = _createStore(createReducer(), initialState, composedEnhancers)
  store.asyncReducers = {}
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.asyncReducers))
  }
  return store
}

function createReducer(asyncReducers = {}) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  })
}

const middlewares = [thunkMiddleware]

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(loggerMiddleware)
}

export const store = createStore(undefined, middlewares)

const StoreProvider = ({children}) => (
  <Provider store={store}>{children}</Provider>
)

StoreProvider.propTypes = {
  children: PropTypes.node,
}

export default StoreProvider
