import {
  TOGGLE_SUBNAV_IS_OPEN,
  SET_SUBNAV_IS_NOT_OPEN,
  SET_SUBNAV_ACTIVE_ROUTE,
  SET_SUBNAV_ACTIVE_SUBROUTE,
  SET_SUBNAV_ACTIVE_ROUTE_WITH_SUBROUTE,
  SET_SUBNAV_LOADING,
  SET_SUBNAV_ROUTES,
  SET_SUBNAV_SUBROUTES,
} from '../types'

const initialState = {
  is_open: false,
  routes: [],
  subroutes: [],
  loading: true,
}

const reducer = (state = initialState, action) => {
  if (action.type === SET_SUBNAV_LOADING) {
    return {...state, loading: action.payload}
  }
  if (action.type === SET_SUBNAV_ROUTES) {
    return {...state, routes: action.payload}
  }
  if (action.type === SET_SUBNAV_SUBROUTES) {
    return {...state, subroutes: action.payload}
  }
  if (action.type === TOGGLE_SUBNAV_IS_OPEN) {
    return {...state, is_open: !state.is_open}
  }
  if (action.type === SET_SUBNAV_IS_NOT_OPEN) {
    return {...state, is_open: false}
  }
  if (action.type === SET_SUBNAV_ACTIVE_ROUTE) {
    return {
      ...state,
      routes: state.routes.map(route => {
        if (route.url === action.payload) return {...route, selected: true}
        return {...route, selected: false}
      }),
    }
  }
  if (action.type === SET_SUBNAV_ACTIVE_SUBROUTE) {
    return {
      ...state,
      subroutes: state.subroutes.map(subroute => {
        if (subroute.url === action.payload)
          return {...subroute, selected: true}
        return {...subroute, selected: false}
      }),
    }
  }
  if (action.type === SET_SUBNAV_ACTIVE_ROUTE_WITH_SUBROUTE) {
    return {
      ...state,
      routes: state.routes.map(route => ({
        ...route,
        selected: route.url === action.payload.route,
      })),
      subroutes: state.subroutes.map(subroute => ({
        ...subroute,
        selected: subroute.url === action.payload.subroute,
      })),
    }
  }
  return state
}

export default reducer
