import {batch} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {withBailOnLoading, setQuery} from './utils'
import {
  SET_INSIGHTS_V2_DATA,
  SET_INSIGHTS_V2_ERROR,
  SET_INSIGHTS_V2_FILTERS,
  SET_INSIGHTS_V2_LOADING,
  SET_INSIGHTS_V2_LOADING_DATA,
  SET_INSIGHTS_V2_NOT_LOADING,
  SET_INSIGHTS_V2_NOT_LOADING_DATA,
  SET_INSIGHTS_V2_OPPORTUNITY_TYPES,
  SET_INSIGHTS_V2_ACTIONABLE_OPPORTUNITY_TYPES,
  SET_INSIGHTS_V2_SURGEONS,
  SET_INSIGHTS_V2_COMPARE_BY,
  SET_INSIGHTS_V2_IS_ACTIONABLE,
  SET_INSIGHTS_V2_DEPARTMENTS,
  SET_INSIGHTS_V2_UNSELECT_DEPARTMENT,
  SET_INSIGHTS_V2_UNSELECT_SUB_DEPARTMENT,
} from '../types'
import {fetchInsightsV2} from '../../api'
import {
  selectOnlyOneById,
  selectOnlyOneByIndex,
  unselectOneById,
  selectOneById,
  fromQueryString,
  selectOrganization,
} from '../../utils'
import {
  selectDefaultDepartment,
  selectIsSystemView,
} from '../../utils/selectors'

const getOpportunityTypeId = state =>
  Number(
    state.insightsV2.opportunity_types
      .filter(ot => ot.selected)
      .map(ot => ot.id)
      .join(),
  )

const setData = payload => ({type: SET_INSIGHTS_V2_DATA, payload})

const setError = payload => ({type: SET_INSIGHTS_V2_ERROR, payload})

const setFilters = (payload, meta) => ({
  type: SET_INSIGHTS_V2_FILTERS,
  payload,
  meta,
})

const setSurgeons = payload => ({type: SET_INSIGHTS_V2_SURGEONS, payload})

const setOpportunityTypes = payload => ({
  type: SET_INSIGHTS_V2_OPPORTUNITY_TYPES,
  payload,
})

const setActionableOpportunityTypes = payload => ({
  type: SET_INSIGHTS_V2_ACTIONABLE_OPPORTUNITY_TYPES,
  payload,
})

const setLoading = () => ({type: SET_INSIGHTS_V2_LOADING})

const setLoadingData = () => ({type: SET_INSIGHTS_V2_LOADING_DATA})

const setNotLoading = () => ({type: SET_INSIGHTS_V2_NOT_LOADING})

const setNotLoadingData = () => ({type: SET_INSIGHTS_V2_NOT_LOADING_DATA})

const setCompareBy = payload => ({type: SET_INSIGHTS_V2_COMPARE_BY, payload})

const setIsActionable = payload => ({
  type: SET_INSIGHTS_V2_IS_ACTIONABLE,
  payload,
})

const selectCompareBy = payload => (dispatch, getState) => {
  const {compare_by: current_compare_by} = getState().insightsV2
  const compare_by = current_compare_by.map(selectOnlyOneById(payload))
  dispatch(setCompareBy(compare_by))
}

const setFetchInsights =
  ({signal, search, initial, is_actionable, actionable_opportunities}) =>
  (dispatch, getState) => {
    if (initial) {
      dispatch(setLoading())
    } else {
      dispatch(setLoadingData())
    }
    const opportunity_type_id = getOpportunityTypeId(getState())
    const organization = selectOrganization(getState())
    const isSystemView = selectIsSystemView(getState())
    return fetchInsightsV2({signal, search})
      .then(({data, filters}) => {
        batch(() => {
          const surgeons =
            opportunity_type_id === 2
              ? filters?.surgeons.map(s => ({...s, selected: false}))
              : filters?.surgeons
          const _data = data.map(d => ({
            ...d,
            organization_name: organization.name,
            organization_id: organization.id,
            organization_group_id: isSystemView
              ? organization.id
              : d?.organization_group_id,
            children: d?.children?.length
              ? d.children.map(d => ({
                  ...d,
                  organization_name: organization.name,
                  organization_id: organization.id,
                  organization_group_id: isSystemView
                    ? organization.id
                    : d?.organization_group_id,
                }))
              : undefined,
          }))
          if (is_actionable) {
            const actionables = [
              ...new Set(
                _data?.filter(d => !!d.insight_type)?.map(d => d.insight_type),
              ),
            ].map(name => ({
              id: name,
              name,
              selected:
                actionable_opportunities?.length > 0
                  ? actionable_opportunities.includes(name)
                  : true,
            }))

            dispatch(setActionableOpportunityTypes(actionables))
          } else {
            if (initial) {
              dispatch(setActionableOpportunityTypes([]))
            }
          }
          dispatch(setFilters({...filters, surgeons}))
          dispatch(setData(_data))
          if (initial) {
            dispatch(setNotLoading())
          } else {
            dispatch(setNotLoadingData())
          }
        })
      })
      .catch(error => {
        batch(() => {
          dispatch(setError(error))
          if (initial) {
            dispatch(setNotLoading())
          } else {
            dispatch(setNotLoadingData())
          }
        })
      })
  }

const selectOpportunityType = (payload, history) => (dispatch, getState) => {
  const {opportunity_types: current_opportunity_types} = getState().insightsV2
  const opportunity_types = current_opportunity_types.map(
    selectOnlyOneById(payload),
  )
  batch(() => {
    dispatch(setOpportunityTypes(opportunity_types))
    dispatch(
      setQuery({
        history,
        store: 'insightsV2',
        onLoading: setLoadingData,
      }),
    )
  })
}

const setDepartments = payload => ({
  type: SET_INSIGHTS_V2_DEPARTMENTS,
  payload,
})

const handleDepartmentsSelection =
  (history, payload) => (dispatch, getState) => {
    batch(() => {
      dispatch(setDepartments(payload))
      dispatch(
        setQuery({
          history,
          store: 'insightsV2',
          onLoading: setLoadingData,
        }),
      )
    })
  }

const toggleSurgeon = (payload, history) => (dispatch, getState) => {
  const {filters: current_filters} = getState().insightsV2
  const {surgeons: current_surgeons} = current_filters
  const surgeons = current_surgeons.map(
    payload.selected ? unselectOneById(payload) : selectOneById(payload),
  )
  batch(() => {
    dispatch(setSurgeons(surgeons))
    dispatch(
      setQuery({
        history,
        store: 'insightsV2',
        onLoading: setLoadingData,
      }),
    )
  })
}

const toggleActionableOpportunityType =
  (payload, history) => (dispatch, getState) => {
    const {actionable_opportunity_types: current_actionable_opportunity_types} =
      getState().insightsV2

    const actionable_opportunity_types =
      current_actionable_opportunity_types.map(t =>
        t.id === payload.id ? {...t, selected: !t.selected} : t,
      )
    batch(() => {
      dispatch(setActionableOpportunityTypes(actionable_opportunity_types))
      dispatch(
        setQuery({
          history,
          store: 'insightsV2',
          fetch: false,
        }),
      )
    })
  }

const selectInsightType = (payload, history) => (dispatch, getState) => {
  const is_actionable = payload === 1

  batch(() => {
    dispatch(setIsActionable(is_actionable))
    dispatch(
      setQuery({
        history,
        store: 'insightsV2',
        onLoading: setLoadingData,
      }),
    )
  })
}

const fetch =
  ({signal, search, bail = false, initial}) =>
  (dispatch, getState) => {
    const {
      is_actionable = false,
      actionable_opportunities = [],
      department_ids,
    } = fromQueryString(search, [
      'is_actionable',
      'actionable_opportunities',
      'department_ids',
    ])
    const default_department = selectDefaultDepartment(getState())?.id

    let query = isEmpty(search) ? '' : `?${search.slice(1)}`

    query = new URLSearchParams(query)

    if (!department_ids && default_department) {
      query.set('department_ids', default_department)
    }

    batch(() => {
      dispatch(setError(null))
      dispatch(setIsActionable(is_actionable))
    })

    if (!bail) {
      dispatch(
        setFetchInsights({
          signal,
          search: query.toString(),
          initial,
          is_actionable,
          actionable_opportunities,
        }),
      )
    }
  }

const resetFilters = history => (dispatch, getState) => {
  const {
    filters: current_filters,
    compare_by: current_compare_by,
    is_actionable,
    actionable_opportunity_types: current_actionable_opportunity_types,
  } = getState().insightsV2
  const {
    procedure_groups: current_procedure_groups,
    surgeons: current_surgeons,
  } = current_filters
  if (!is_actionable) {
    const procedure_groups = current_procedure_groups.map(
      selectOnlyOneByIndex(0),
    )
    const surgeons = current_surgeons.map(surgeon => ({
      ...surgeon,
      selected: false,
    }))
    const compare_by = current_compare_by.map(selectOnlyOneById({id: 1}))

    batch(() => {
      dispatch(setCompareBy(compare_by))

      dispatch(
        setQuery({
          history,
          store: 'insightsV2',
          overrides: {
            procedure_groups,
            surgeons,
            departments: [],
          },
          onLoading: setLoadingData,
        }),
      )
    })
  } else {
    const actionable_opportunity_types =
      current_actionable_opportunity_types?.map(t => ({
        ...t,
        selected: false,
      }))
    batch(() => {
      dispatch(setActionableOpportunityTypes(actionable_opportunity_types))
      dispatch(
        setQuery({
          history,
          store: 'insightsV2',
          fetch: false,
        }),
      )
    })
  }
}

const unselectDepartment = (payload, history) => (dispatch, getState) => {
  batch(() => {
    dispatch({type: SET_INSIGHTS_V2_UNSELECT_DEPARTMENT, payload})
    dispatch(
      setQuery({
        history,
        store: 'insightsV2',
        onLoading: setLoadingData,
      }),
    )
  })
}

const unselectSubDepartment = (payload, history) => (dispatch, getState) => {
  batch(() => {
    dispatch({type: SET_INSIGHTS_V2_UNSELECT_SUB_DEPARTMENT, payload: payload})
    dispatch(
      setQuery({
        history,
        store: 'insightsV2',
        onLoading: setLoadingData,
      }),
    )
  })
}

const setAndFetchSurgeons = (payload, history) => (dispatch, getState) => {
  batch(() => {
    dispatch(setSurgeons(payload))
    dispatch(
      setQuery({
        history,
        store: 'insightsV2',
        onLoading: setLoadingData,
      }),
    )
  })
}

const actions = {
  fetch,

  selectOpportunityType: withBailOnLoading(selectOpportunityType, 'insightsV2'),
  toggleSurgeon: withBailOnLoading(toggleSurgeon, 'insightsV2'),
  resetFilters: withBailOnLoading(resetFilters, 'insightsV2'),
  selectInsightType: withBailOnLoading(selectInsightType, 'insightsV2'),
  setDepartments: withBailOnLoading(handleDepartmentsSelection, 'insightsV2'),
  selectCompareBy,
  toggleActionableOpportunityType,
  unselectSubDepartment: withBailOnLoading(unselectSubDepartment, 'insightsV2'),
  unselectDepartment: withBailOnLoading(unselectDepartment, 'insightsV2'),
  setSurgeons: withBailOnLoading(setAndFetchSurgeons, 'insightsV2'),
}

export default actions
