import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {createPortal} from 'react-dom'
import {
  AgBox,
  AgColumn,
  AgRow,
  AgText,
  AgImage,
  AgButton,
  usePortal,
  useOnClickOutside,
} from '@aghealth/ag-components'
import {useLocation} from 'react-router-dom'
import Modal from './Modal'
import closeIcon from '../assets/icon-close.png'

const TermsOfUse = ({onClose}) => {
  const ref = React.useRef()
  useOnClickOutside(ref, onClose, document)
  return (
    <AgColumn
      ref={ref}
      width="600px"
      bg="white"
      mt="50px"
      mb="50px"
      px="30px"
      borderRadius="base"
      sx={{variant: 'scrollbars.y-hide'}}
    >
      <AgRow
        borderBottom="base"
        borderColor="gray"
        justifyContent="space-between"
        py="16px"
      >
        <AgText fontSize="16px" lineHeight="20px" fontWeight="bold">
          Terms of Use
        </AgText>
        <AgImage
          src={closeIcon}
          onClick={onClose}
          sx={{cursor: 'pointer', size: 18}}
        />
      </AgRow>
      <AgColumn borderBottom="base" borderColor="gray">
        <AgText fontSize="14px" lineHeight="20px" pt={2}>
          Please review these Terms of Use carefully before using the website
          www.CareMeasurement.com (the “Site”). By accessing, posting to or
          otherwise using the Site, you agree to be bound by these Terms of Use.
          If you do not agree with any part of these Terms of Use, you must not
          use the Site. Your continued use of the Site constitutes your
          acceptance of these Terms of Use.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Permitted Use</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          All of the content on the Site is made available only for your lawful
          use in connection with&nbsp;any purpose approved in writing by
          Avant-garde Health, Inc. (“AGH”). Use of the Site by any person or
          entity who has does not have written authorization from AGH is
          expressly prohibited. You may not, without AGH’s prior written
          permission: (i) link from another website to the Site; (ii) post,
          transmit, copy, modify, create derivative works from, distribute,
          sell, or republish anything you obtain or download from the Site;
          (iii)&nbsp;engage in systematic retrieval of data or other content
          from the Site; or (iv)&nbsp;frame the site in another website.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Intellectual Property</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          Except as otherwise stated, all materials on the Site, including
          without limitation the Care Measurement logo and other Care
          Measurement trademarks and service marks (the “AGH Intellectual
          Property”), are the property of AGH and/or its Affiliates or licensors
          and are protected by international copyright and trademark laws, all
          rights reserved. You may only use materials obtained from the Site for
          the permitted purposes set forth in these Terms of Use, provided that
          the following copyright notice appears in all copies of any downloaded
          or printed materials: “© 2015 Avant-garde Health, Inc.. All rights
          reserved.” All other uses are prohibited. Your unauthorized use of the
          AGH Intellectual Property shall result in your immediate termination
          of use of the Site in addition to all other remedies available to AGH.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Products and Services</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          The products and services offered on the Site are only available to
          those entities that are approved by AGH.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Disclaimer of Warranties</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          All information, products, content and other materials on the site,
          accessible from the site, or on any third party site, as defined
          below, are provided “as is”, “where is” and without warranties or
          representations of any kind, either express or implied. This means
          that AGH expressly disclaims all warranties, express or implied,
          including, without limitation, the warranties of title and
          non-infringement and the implied warranties of merchantability and
          fitness for a particular purpose. In addition, AGH does not represent
          or warrant that: (i) the operation of the site will be error-free or
          uninterrupted; (ii) defects will be corrected; (iii) the site, any
          emails sent by AGH, or the server that makes the site available are
          free of viruses or other harmful devices; (iv) the site will be
          available to you or that you will qualify for products or services
          offered; (v) the materials on the site will be accurate as of any
          particular date; or (vi) the products or services offered through the
          site are available outside of the united states or in jurisdictions in
          which AGH is not properly licensed.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Third-Party Sites</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          At times, the Site may have links to websites hosted by other parties
          (“Third-Party Sites”), or such Third-Party Sites may have links to the
          Site. These links are offered as a convenience and for informational
          purposes only, not as referrals or endorsements by AGH of the
          Third-Party Sites. The Third-Party Sites are maintained by their
          respective organizations, and those organizations are solely
          responsible for the content of the Third-Party Sites. AGH does not
          verify nor make any warranty or representation about the content,
          accuracy, opinions expressed, warranties, products, services,
          intellectual property compliance, or links of such Third-Party Sites.
          It is solely your responsibility to read the privacy policies and
          terms of use agreements of all Third-Party Sites.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Limitation of Liability</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          AGH disclaims all liability for any loss, damage, injury, claim,
          special, indirect, incidental, consequential, exemplary or punitive
          damages of any kind, whether such action is based in tort, contract,
          negligence, strict liability, or otherwise and even if AGH has been
          advised of the possibility of such damages. These limitations shall
          apply notwithstanding any failure of essential purpose of any limited
          remedy.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Jurisdiction, Enforceability, and Termination</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          These Terms of Use and your use of the Site are governed in all
          respects by the laws of the Commonwealth of Massachusetts, without
          giving effect to any principles of conflicts of laws. Any dispute
          concerning the Site or these Terms of Use shall be subject to the
          exclusive venue of a court of competent jurisdiction in Suffolk
          County, Massachusetts. In the event that a court of competent
          jurisdiction holds any provision of these Terms of Use to be invalid
          or unenforceable, then such provision shall be severable from and
          shall not affect the validity or enforceability of the remaining
          provisions.
        </AgText>
        <AgText fontSize="14px" lineHeight="20px" pt={2}>
          These Terms of Use are effective until terminated by either party. You
          may terminate these Terms of Use without notice to AGH by
          discontinuing all use of the Site and destroying any and all materials
          or information that you have obtained from the Site, including all
          related documentation and copies. AGH may terminate these Terms of Use
          and/or the products or services offered by AGH at any time without
          notice to you if, in AGH’s sole judgment, you breach any term or
          condition of these Terms of Use. Upon termination, you shall
          immediately discontinue all use of the materials or information
          contained on the Site and destroy any and all materials you have
          obtained from AGH or the Site, including all related documentation and
          copies. AGH may terminate all or part of the Site at any time, without
          notice to you.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Right to Modify and Change</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          AGH reserves the right to modify, change or otherwise amend these
          Terms of Use at any time and without prior notice. It is your sole
          responsibility to monitor and review these Terms of Use for any such
          modifications, changes or amendments. Your continued use of the Site
          shall constitute acceptance of any such modification, change or
          amendment.
        </AgText>

        <AgText fontSize="14px" lineHeight="20px" py={2}>
          <strong>Indemnification and Release</strong>
        </AgText>
        <AgText fontSize="14px" lineHeight="20px">
          You shall indemnify and hold AGH and its subsidiaries, affiliates,
          officers, managers, employees and agents harmless from and against any
          and all claims or liabilities, including costs and attorneys’ fees,
          arising from or in connection with your use of the Site, your
          violation of these Terms of Use, your violation of the right of any
          third party, or your failure to abide by applicable law. You hereby
          release and waive any and all claims, rights of action and/or
          liabilities against AGH and its subsidiaries, affiliates, officers,
          managers, employees and agents arising from or in connection with your
          use of the Site or anything related to the Site. AGH reserves and you
          grant to AGH the right to assume exclusive defense and control of any
          matter subject to indemnification by you. These indemnification and
          release provisions shall survive termination of these Terms of Use.
        </AgText>
        <AgText fontSize="14px" lineHeight="20px" py={2}>
          These Terms of Use state the entire agreement with regard to the items
          set forth in these Terms of Use.
        </AgText>
      </AgColumn>
      <AgRow width="100%" justifyContent="space-between" py="16px">
        <AgText fontSize="14px" lineHeight="20px">
          2021 © Avant-garde Health, Inc.
        </AgText>
        <AgButton
          fontSize="14px"
          lineHeight="20px"
          bg="white"
          border="base"
          borderRadius="4px"
          p="4px"
          borderColor="#1b263f"
          onClick={onClose}
        >
          Close
        </AgButton>
      </AgRow>
    </AgColumn>
  )
}

TermsOfUse.propTypes = {
  onClose: PropTypes.func,
}

const Footer = () => {
  const target = usePortal('terms-of-use', document)
  const sessionTime = useSelector(state => state.config.session_time)
  const year = new Date().getFullYear()
  const [show, setShow] = React.useState(false)
  const {pathname} = useLocation()
  const isScorecard = React.useMemo(() => {
    const name = pathname.split('/')[1]
    return name === 'scorecard'
  }, [pathname])
  const toggleShow = React.useCallback(() => {
    setShow(prev => !prev)
  }, [])
  const setNoShow = React.useCallback(() => {
    setShow(false)
  }, [])
  return (
    <AgBox
      bg="white"
      width="100%"
      sx={{position: 'fixed', bottom: 0, left: 0, zIndex: 5000}}
    >
      <AgRow px="82px" width="100%" height="50px">
        {!isScorecard && (
          <>
            <AgText fontSize="14px" lineHeight="20px">
              {year} &copy; Avant-garde Health, Inc.
            </AgText>
            <span>&nbsp;|&nbsp;</span>
            <AgText
              fontSize="14px"
              lineHeight="20px"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={toggleShow}
            >
              Terms of Use
            </AgText>
            <span>&nbsp;|&nbsp;</span>
            <AgText fontSize="14px" lineHeight="20px">
              {`Auto-logout: ${sessionTime}`}
            </AgText>
          </>
        )}
      </AgRow>
      {createPortal(
        React.cloneElement(
          <Modal>
            <TermsOfUse />
          </Modal>,
          {
            visible: show,
            onClose: setNoShow,
          },
        ),
        target,
      )}
    </AgBox>
  )
}

export default Footer
