import isObject from 'lodash/isPlainObject'
import max from 'lodash/max'
import merge from 'lodash/merge'
import {measureText} from '../../../../../utils'
import {computeBandLabelKey, computeBandSublabelKey} from '../utils'

const hasLegend = config => config.chart.keys || config.options

const computeMaxTextWidth = (data, labelKey, sublabelKey) =>
  max(
    data.map(d =>
      Math.max(
        measureText(d[labelKey]).width,
        sublabelKey ? Math.max(measureText(d[sublabelKey]).width, 80) : 80,
      ),
    ),
  )

const computeTickWidth = config => {
  const labelKey = computeBandLabelKey(config)
  const sublabelKey = computeBandSublabelKey(config)
  const width = computeMaxTextWidth(config.data, labelKey, sublabelKey)

  if (config.id === 48) return width + 24
  return width
}

const computeMarginLeft = (config, width) => {
  if (config.chart.dimension === 'x') return width + 35
  return 100
}

const computeMarginBottom = (config, width) => {
  if (config.chart.dimension === 'y') return width
  return 100
}

const computeMarginRight = (config, margin) => {
  if (config.chart.dimension === 'x') return 0
  return hasLegend(config) ? margin / 2 : margin / 1.5
}

const createDimensions = config => {
  const tickWidth = computeTickWidth(config)
  const marginLeft = computeMarginLeft(config, tickWidth)
  const marginBottom = computeMarginBottom(config, tickWidth)
  const initial = isObject(config.chart.initialDimensions)
    ? config.chart.initialDimensions
    : {paddingAvg: 0.25}
  const dimensions = {
    tickWidth,
    marginTop: config.id === 48 ? 20 : 50,
    marginLeft,
    marginBottom,
    minBarWidth: 45,
    marginRight: computeMarginRight(
      config,
      config.chart.dimension === 'y' ? marginBottom : marginLeft,
    ),
  }
  return merge({}, initial, dimensions)
}

export default createDimensions
