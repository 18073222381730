export const SET_CHARTS_DATA = 'SET_CHARTS_DATA'
export const SET_CHARTS_CONFIG = 'SET_CHARTS_CONFIG'
export const SET_CHARTS_LOADING = 'SET_CHARTS_LOADING'
export const SET_CHARTS_NOT_LOADING = 'SET_CHARTS_NOT_LOADING'
export const SET_CHARTS_LOADING_DATA = 'SET_CHARTS_LOADING_DATA'
export const SET_CHARTS_NOT_LOADING_DATA = 'SET_CHARTS_NOT_LOADING_DATA'
export const SET_CHARTS_ERROR = 'SET_CHARTS_ERROR'
export const SET_CHARTS_TIME_PERIODS = 'SET_CHARTS_TIME_PERIODS'
export const SET_CHARTS_CATEGORIES = 'SET_CHARTS_CATEGORIES'
export const SET_CHARTS_PROCEDURE_GROUPS = 'SET_CHARTS_PROCEDURE_GROUPS'
export const SET_CHARTS_ICDS = 'SET_CHARTS_ICDS'
export const SET_CHARTS_DRGS = 'SET_CHARTS_DRGS'
export const SET_CHARTS_EXCLUDE_IP = 'SET_CHARTS_EXCLUDE_IP'
export const SET_CHARTS_EXCLUDE_OP = 'SET_CHARTS_EXCLUDE_OP'
export const SET_CHARTS_ALL_INPATIENT = 'SET_CHARTS_ALL_INPATIENT'
export const SET_CHARTS_ALL_OUTPATIENT = 'SET_CHARTS_ALL_OUTPATIENT'
export const SET_CHARTS_CPTS = 'SET_CHARTS_CPTS'
export const SET_CHARTS_INSURANCE_TYPES = 'SET_CHARTS_INSURANCE_TYPES'
export const SET_CHARTS_PROCEDURES = 'SET_CHARTS_PROCEDURES'
export const SET_CHARTS_INSTRUMENT_TYPES = 'SET_CHARTS_INSTRUMENT_TYPES'
export const SET_CHARTS_HOSPITAL_CASE_NUMBERS =
  'SET_CHARTS_HOSPITAL_CASE_NUMBERS'
export const SET_CHARTS_PERCENTILES = 'SET_CHARTS_PERCENTILES'
export const SET_CHARTS_FILTERS = 'SET_CHARTS_FILTERS'
export const SET_CHARTS_DATE_SAVED = 'SET_CHARTS_DATE_SAVED'
export const SET_CHARTS_RISK_ADJUSTED = 'SET_CHARTS_RISK_ADJUSTED'
export const SET_CHARTS_QUALITY_METRIC = 'SET_CHARTS_QUALITY_METRIC'
export const SET_CHARTS_CANCER_SITE = 'SET_CHARTS_CANCER_SITE'
export const SET_CHARTS_COMORBIDITIES = 'SET_CHARTS_COMORBIDITIES'
export const SET_CHARTS_HISTOLOGIES = 'SET_CHARTS_HISTOLOGIES'
export const SET_CHARTS_PROCEDURE_DETAILS = 'SET_CHARTS_PROCEDURE_DETAILS'
export const SET_CHARTS_STAGES = 'SET_CHARTS_STAGES'
export const SET_CHARTS_DIAGNOSIS_CODES = 'SET_CHARTS_DIAGNOSIS_CODES'
export const SET_CHARTS_DELETED = 'SET_CHARTS_DELETED'
export const CLEAR_CHARTS_DELETED = 'CLEAR_CHARTS_DELETED'
export const SET_CHARTS_DROPPED_FILTERS = 'SET_CHARTS_DROPPED_FILTERS'
export const TOGGLE_CHARTS_SHOW_NUMBERS = 'TOGGLE_CHARTS_SHOW_NUMBERS'
export const TOGGLE_CHARTS_SHOW_PHYSICIAN_NAMES =
  'TOGGLE_CHARTS_SHOW_PHYSICIAN_NAMES'
export const TOGGLE_CHARTS_SHOW_MORE = 'TOGGLE_CHARTS_SHOW_MORE'
export const TOGGLE_CHARTS_GROUP_BY_SUPPLY_CATEGORY =
  'TOGGLE_CHARTS_GROUP_BY_SUPPLY_CATEGORY'
export const TOGGLE_CHARTS_GROUP_BY_MANUFACTURER =
  'TOGGLE_CHARTS_GROUP_BY_MANUFACTURER'
export const TOGGLE_CHARTS_SHOW_PERCENTILES = 'TOGGLE_CHARTS_SHOW_PERCENTILES'
export const TOGGLE_CHARTS_SHOW_ALL_CASE_SUPPLY_COSTS =
  'TOGGLE_CHARTS_SHOW_ALL_CASE_SUPPLY_COSTS'
export const TOGGLE_CHARTS_HIDE_CASE_REMOVAL_BUTTONS =
  'TOGGLE_CHARTS_HIDE_CASE_REMOVAL_BUTTONS'
export const SET_CHARTS_GROUP_BY_SURGEON = 'SET_CHARTS_GROUP_BY_SURGEON'
export const SET_CHARTS_GROUP_BY_ORGANIZATION =
  'SET_CHARTS_GROUP_BY_ORGANIZATION'
export const SET_CHARTS_EXCLUDE_DIAGNOSIS_CODES =
  'SET_CHARTS_EXCLUDE_DIAGNOSIS_CODES'
export const SET_CHARTS_IS_ONCOLOGY = 'SET_CHARTS_IS_ONCOLOGY'
export const SET_CHARTS_EXCLUDE_ONCOLOGY = 'SET_CHARTS_EXCLUDE_ONCOLOGY'
export const SET_CHARTS_IS_ROBOTICS = 'SET_DASHBOARD_IS_ROBOTICS'
export const SET_CHARTS_EXCLUDE_ROBOTICS = 'SET_DASHBOARD_EXCLUDE_ROBOTICS'
export const SET_CHARTS_SUPPLY_PRODUCTS = 'SET_CHARTS_SUPPLY_PRODUCTS'
export const SET_CHARTS_SUPPLY_MANUFACTURER_NUMBERS =
  'SET_CHARTS_SUPPLY_MANUFACTURER_NUMBERS'
export const SET_CHARTS_COST_BY_CASE_SEARCH = 'SET_CHARTS_COST_BY_CASE_SEARCH'
export const SET_CHARTS_COST_BY_SUPPLY_SEARCH =
  'SET_CHARTS_COST_BY_SUPPLY_SEARCH'
export const TOGGLE_CHARTS_SHOW_COSTS = 'TOGGLE_CHARTS_SHOW_COSTS'
export const SET_CHARTS_DEPARTMENTS = 'SET_CHARTS_DEPARTMENTS'
export const UNSELECT_SUB_DEPARTMENT = 'UNSELECT_SUB_DEPARTMENT'
export const UNSELECT_DEPARTMENT = 'UNSELECT_DEPARTMENT'
export const SET_HIGH_VOLUME_PROCEDURE = 'SET_HIGH_VOLUME_PROCEDURE'
export const SET_HIGH_VOLUME_PROCEDURE_SELECTION =
  'SET_HIGH_VOLUME_PROCEDURE_SELECTION'
