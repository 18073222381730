import {baseURL, getHeaders} from './utils'
import {toFilteredQueryString} from '../utils'

const endpoint = `${baseURL}/api/insights/v2`

export const fetchInsightsV2 = async options => {
  const {search: _search, signal} = options
  const search = toFilteredQueryString(_search, ['actionable_opportunities'])
  const response = await fetch(endpoint.concat(search), {
    headers: await getHeaders(),
    signal,
  })
  const {data, ...json} = await response.json()
  const payload = {
    ...json,
    data: data?.map(d => ({
      ...d,
      insight_type:
        d?.insight_type === 'Standardize Construct'
          ? 'Standardize'
          : d?.insight_type,
    })),
  }
  return Promise.resolve(payload)
}
